import { Navigate, Route, Routes } from "react-router-dom";

import AboutPage from "../pages/About";
import SettingsPage from "../pages/Settings";
import AdminPage from "../pages/Admin";
import CreateMythPage from "../pages/CreateMyth";
import EditMythPage from "../pages/EditMyth";
import HomePage from "../pages/Home";
import MythPage from "../pages/Myth";
import MythsPage from "../pages/Myths";
import MyMythsPage from "../pages/MyMyths";
import PrivacyPolicyPage from "../pages/PrivacyPolicy";
import ProfilePage from "../pages/Profile";
import ResourcesPage from "../pages/Resources";
import TermsofServicePage from "../pages/TermsOfService";
import VerifyPage from "../pages/Verify";

const AppRouter = () => {
    return (
        <Routes>
            <Route path='/' element={<HomePage />} />
            <Route path='/about' element={<AboutPage />} />
            <Route path='/myth/create' element={<CreateMythPage />} />
            <Route path='/myth/edit/:mythId' element={<EditMythPage />} />
            <Route path='/myth/:mythId' element={<MythPage />} />
            <Route path='/mymyths' element={<MyMythsPage />} />
            <Route path='/myths' element={<MythsPage />} />
            <Route path='/privacy' element={<PrivacyPolicyPage />} />
            <Route path='/profile/:username' element={<ProfilePage />} />
            <Route path='/resources' element={<ResourcesPage />} />
            <Route path='/terms' element={<TermsofServicePage />} />
            <Route path='/verify/:code' element={<VerifyPage />} />

            <Route path='/settings' element={<SettingsPage />} />
            <Route path='/admin' element={<AdminPage />} />

            <Route path='*' element={<Navigate to="/" />} />
        </Routes>
    );
}

export default AppRouter;