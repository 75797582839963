const STATIC_URL = process.env.REACT_APP_STATIC_URL;

export function getBadge(badgeId: number) {
    switch (badgeId) {
        case 1: // Verified
            return "success";
        case 2: // Myth Creator
            return "danger";
        case 3: // Mythos Moderator
            return "primary";
        case 4: // Mythos Administrator
            return "mythos";
        case 5: // Patreon Supporter
            return "warning";
        case 6: // Discord Booster
            return "booster";
        case 7: // BlackBird Interactive
            return "dark";
        case 8: // Mojang Staff
            return "mojang";
        default: // Unknown
            return "danger";
    }
}

export function encodeFileBase64(file: File | Blob): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            resolve(reader.result as string);
        };

        reader.readAsDataURL(file);
        reader.onerror = reject;
    });
}

export function getProfilePicture(username?: string) {
    let fileName = username ?? 'default';
    let fileType = 'jpg';

    if (fileName.length === 0) fileName = 'default';
    return `${STATIC_URL}/users/${fileName}.${fileType}`;
}

export function getMythImage(mythHash: string) {
    return `${STATIC_URL}/myths/${mythHash}.jpg`;
}

export function formatDescription(description: string) {
    if (!description) return description;

    // Add <br /> for new lines
    description = description.replace(/(?:\\r\\n|\r\n)/g, '<br /><br />');
    //description = description.replace(/(?:\r)/g, '<br />');

    // Replace bbcode with html
    description = description.replace(/\[h([0-5])\](.*?)\[\/h([0-5])\]/g, '<h$1>$2</h$3>');
    description = description.replace(/\[b\](.*?)\[\/b\]/g, '<strong>$1</strong>');
    description = description.replace(/\[i\](.*?)\[\/i\]/g, '<em>$1</em>');
    description = description.replace(/\[u\](.*?)\[\/u\]/g, '<u>$1</u>');
    description = description.replace(/\[s\](.*?)\[\/s\]/g, '<s>$1</s>');
    description = description.replace(/\[img\](.*?)\[\/img\]/g, '<img src="$1" />');
    description = description.replace(/\[url=(.*?)\](.*?)\[\/url\]/g, '<a href="$1" target="_blank">$2</a>');
    description = description.replace(/\[url\](.*?)\[\/url\]/g, '<a href="$1" target="_blank">$1</a>');
    description = description.replace(/\[previewyoutube=(.*?)\]\[\/previewyoutube\]/g, '<iframe class="w-50" style="height: 350px" src="https://youtube.com/embed/$1" title="Youtube Preview" allowFullscreen />');
    //description = description.replace(/(https?:\/\/[^\s]+)/g, '<a href="$1" target="_blank">$1</a>');
    description = description.replace(/\[color=(.*?)\](.*?)\[\/color\]/g, '<span style="color: $1">$2</span>');
    description = description.replace(/\[size=(.*?)\](.*?)\[\/size\]/g, '<span style="font-size: $1px">$2</span>');
    description = description.replace(/\[center\](.*?)\[\/center\]/g, '<div style="text-align: center">$1</div>');
    description = description.replace(/\[left\](.*?)\[\/left\]/g, '<div style="text-align: left">$1</div>');
    description = description.replace(/\[right\](.*?)\[\/right\]/g, '<div style="text-align: right">$1</div>');
    description = description.replace(/\[justify\](.*?)\[\/justify\]/g, '<div style="text-align: justify">$1</div>');
    description = description.replace(/\[quote\](.*?)\[\/quote\]/g, '<blockquote>$1</blockquote>');
    description = description.replace(/\[quote=(.*?)\](.*?)\[\/quote\]/g, '<blockquote><strong>$1</strong><br />$2</blockquote>');
    description = description.replace(/\[code\](.*?)\[\/code\]/g, '<pre>$1</pre>');
    description = description.replace(/\[code=(.*?)\](.*?)\[\/code\]/g, '<pre class="$1">$2</pre>');

    //description = description.replace(/\[list\](.*)\[\/list\]/g, '<ul>$1</ul>');

    description = replaceNestedLists(description);

    description = description.replace(/\[olist=(.*?)\](.*?)\[\/olist\]/g, '<ol type="$1">$2</ol>');
    description = description.replace(/\[\*\](.*?)\n/g, '<li>$1</li>');

    function replaceNestedLists(input: string) {
        let output = input;
        let match;
        while ((match = /\[list\]([\s\S]*?)\[\/list\]/i.exec(output)) !== null) {
            const nestedList = match[0];
            const listContent = match[1];
            const ulContent = `<ul>${listContent}</ul>`;
            output = output.replace(nestedList, ulContent);
        }
        return output;
    }

    return description;
}

export function limitCharacters(input: string, limit: number) {
    if (input.length <= limit) {
        return input; // No need to truncate
    } else {
        return input.slice(0, limit) + '...';
    }
}