import { useEffect, useState } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { PersonCheck } from "react-bootstrap-icons";

import AdminApiService from "../../../services/AdminApiService";
import DownloadService from "../../../services/DownloadService";
import { Link } from "react-router-dom";
import { getMythImage } from "../../../utilities/Helpers";

const ReviewMythModal = ({ requestId, mythId }: { requestId: number, mythId: number }) => {
    const [show, setShow] = useState(false);
    const [rejectionReason, setRejectionReason] = useState<string>();
    const [myth, setMyth] = useState<Myth>();

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const approveMyth = (e: any) => {
        e.preventDefault();

        AdminApiService.post(`/myth/${myth?.id}/request/${requestId}/approve`).then((response) => {
            if (response && response.success)
                handleClose();
            else throw new Error(response.message);
        }).catch(console.log);

        console.log(myth);
    }

    const rejectMyth = (e: any) => {
        e.preventDefault();
        if (!rejectionReason) {
            alert('Rejection reason required')
            return;
        }

        AdminApiService.post(`/myth/${myth?.id}/request/${requestId}/reject`, { reason: rejectionReason }).then((response) => {
            if (response && response.success)
                handleClose();
            else throw new Error(response.message);
        }).catch(console.log);
    }

    useEffect(() => {
        AdminApiService.get(`/myth/${mythId}`).then((response) => {
            if (response && response.success) {
                setMyth(response.data);
            } else throw new Error(response.message);
        }).catch(console.log);
    }, [mythId]);

    if (!myth) return (<></>);

    return (<>
        <Modal show={show} onHide={handleClose} className="dark-modal" aria-labelledby="Review Myth Form" size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Review Myth</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <h3>Details</h3>
                        Myth ID: {myth.id}<br />
                        Myth Name: {myth.name}<br />
                        Creator: <Link to={`/profile/${myth.creator.username}`} target="_blank">{myth.creator.username}</Link><br />
                        Game Mode: {myth.game_mode}<br />
                        Category: {myth.category.name}<br />
                        Short Description: {myth.short_description}<br />
                    </Col>
                    <Col>
                        <h3>Links</h3>
                        Download Link: {(myth.versions && (myth.versions.length > 0 && <a href={DownloadService.getMythByHash(myth.versions[0].file_hash)} target="_blank" rel="noreferrer">Download</a>)) || 'N/A'}<br />
                        Trailer Link: {(myth.youtube_link && <a href={myth.youtube_link} target="_blank" rel="noreferrer" title="Trailer Link">{myth.youtube_link}</a>) || 'N/A'}<br />
                        GitHub Link: {(myth.github_link && <a href={myth.github_link} target="_blank" rel="noreferrer" title="GitHub Link">{myth.github_link}</a>) || 'N/A'}<br />
                        Twitter Link: {(myth.twitter_link && <a href={myth.twitter_link} target="_blank" rel="noreferrer" title="Twitter Link">{myth.twitter_link}</a>) || 'N/A'}<br />
                        Discord Link: {(myth.discord_link && <a href={myth.discord_link} target="_blank" rel="noreferrer" title="Discord Link">{myth.discord_link}</a>) || 'N/A'}<br />
                    </Col>
                </Row>
                <br />
                <Row>
                    <h3>Images</h3>
                    {(!myth.images || myth.images.length === 0) && <Col>No images uploaded</Col>}
                    {myth.images && myth.images.map((image, index) => {
                        return (
                            <Col key={image.id}>
                                <img src={getMythImage(image.image_hash)} alt={image.image_hash} className="img-fluid" />
                            </Col>
                        )
                    })}
                </Row>
                <br />
                <Row>
                    <Col>
                        <h3>Long Description</h3>
                        {myth.long_description || 'N/A'}<br />
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col>
                        <b>Rejection Reason</b>
                        <textarea className="form-control" onChange={(e) => setRejectionReason(e.target.value)} />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button className="m-btn m-btn-secondary" onClick={handleClose}>Close</Button>
                <Button className="m-btn m-btn-danger" onClick={rejectMyth}>Reject</Button>
                <Button className="m-btn m-btn-success" onClick={approveMyth}>Approve</Button>
            </Modal.Footer>
        </Modal>
        <Button className="m-btn m-btn-primary me-2 py-0" title="Review" disabled={myth.approved} onClick={handleShow}>
            <PersonCheck />
        </Button>
    </>
    );
}

export default ReviewMythModal;