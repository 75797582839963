import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, Carousel, Col, Container, Row } from "react-bootstrap";

import discord32bit from "../styles/images/icons/discord-32bit.png";
import craftingBench from '../styles/images/icons/crafting-bench.png';
import legendsIcon from '../styles/images/icons/icon.png';

import FeedService from "../services/FeedService";
import ApiService from "../services/ApiService";

import { getMythImage } from "../utilities/Helpers";

const HomePage = () => {
    const [steamFeed, setSteamFeed] = useState<SteamNews[]>();
    const [loading, setLoading] = useState(true);

    const [myths, setMyths] = useState<[]>([]);

    useEffect(() => {
        ApiService.get('/myths').then((response) => {
            setMyths(response.data);
        }).catch(console.log).finally(() => {
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        FeedService.getSteamNewsFeed().then(setSteamFeed);
    }, []);

    const FillerNews = () => {
        let output: JSX.Element[] = [];

        for (let i = 1; i <= 3; i++) {
            //let postDate = new Date(Number(`${feedItem.date}000`));
            output.push(<Card key={i} className="mb-4">
                <Card.Header>
                    <Card.Title className="pt-2">
                        Filler News Article Title
                    </Card.Title>
                    <i className="fs-7">Posted by Shadow386 on 2023/10/17 @ 10:08 PM</i>
                </Card.Header>
                <Card.Body dangerouslySetInnerHTML={{
                    __html: `
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                    et dolore magna aliqua. Tellus id interdum velit laoreet id. Est lorem ipsum dolor sit amet consectetur
                    adipiscing. Nec ullamcorper sit amet risus nullam eget. Hac habitasse platea dictumst quisque sagittis
                    purus sit amet. Ultricies lacus sed turpis tincidunt id aliquet risus feugiat in. Suscipit tellus
                    mauris a diam. Rhoncus aenean vel elit scelerisque mauris pellentesque pulvinar pellentesque habitant.
                    Consequat semper viverra nam libero justo laoreet sit amet. Nulla pellentesque dignissim enim sit amet
                    venenatis urna cursus eget. At tempor commodo ullamcorper a.
                    `
                }}>
                </Card.Body>
                <Card.Footer className="text-end text-light">
                    <a href="/">Read More &gt;</a>
                </Card.Footer>
            </Card>);
        }

        return <>{output}</>;
    }

    return <Container className="pt-4 pt-md-0 pb-5">
        <Row className="mt-3 mt-md-5 pt-md-5 text-center">
            <Col>
                <h1 className="display-2 header">Welcome to Mythos!</h1>
                <div className="d-none d-md-block">
                    <hr className="divider mx-auto my-3" />
                    <h2 className="my-4 header text-center">Featured Myths</h2>
                    <Carousel className="w-75 mx-auto mb-5">
                        {myths && myths.reverse().slice(0, 3).map((myth: Myth) => {
                            return (
                                <Carousel.Item className="carousel-filler br-3">
                                    <img src={getMythImage(myth.default_image)} alt="Myth Image 1" className="box-shadow-inset" />
                                    <Carousel.Caption className="text-light">
                                        <h2 className="myth-info bi-3 bg-dark br-3 w-50 py-2 mx-auto header">
                                            {myth.name}
                                        </h2>
                                        <Link to={`/myth/${myth.id}`} className="btn m-btn m-btn-primary my-3 fs-6">Learn More</Link>
                                    </Carousel.Caption>
                                </Carousel.Item>
                            )
                        })}
                    </Carousel>
                </div>
                <hr className="large-divider w-100 w-md-75 mx-auto" />
                <Row className="justify-content-evenly mt-2 mt-md-5">
                    <Col xs={12} md={4} className="mb-4 mb-md-1">
                        <img src={craftingBench} width={128} alt="Create" />
                        <div className="dark-bg bi-3 p-3 mt-3">
                            <p className="fs-5">Create Myths using official and unofficial documentation documentation on our Resources page</p>
                            <Link to='/resources' className="btn m-btn m-btn-primary">
                                View Resources
                            </Link>
                        </div>
                    </Col>
                    <Col xs={12} md={4} className="mb-4 mb-md-1 pt-4">
                        <img src={legendsIcon} width={96} alt="Share" />
                        <div className="dark-bg bi-3 p-3 mt-4">
                            <p className="fs-5">Share your Myth with the Legends Modding community and check out what others have made!</p>
                            <Link to='/myths' className="btn m-btn m-btn-primary">
                                Expore Myths
                            </Link>
                        </div>
                    </Col>
                    <Col xs={12} md={4} className="mb-4 mb-md-1">
                        <img src={discord32bit} width={128} alt="Share" />
                        <div className="dark-bg bi-3 p-3 mt-3">
                            <p className="fs-5">Join our Discord and hang out with other community members and participate in events, including developer AMAs!</p>
                            <a href='https://discord.gg/QP2ESnWjbr' target="_blank" className="btn m-btn m-btn-discord">
                                Join our discord
                            </a>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
        <hr className="large-divider mt-2 mb-4 my-md-4" />
        <Row>
            <Col>
                <h1 className="mb-4 header">Legends Modding News</h1>
                <Card key={0} className="mb-4">
                    <Card.Header>
                        <Card.Title className="pt-2">
                            Mythos launches new layout!
                        </Card.Title>
                        <i className="fs-7">Posted by Shadow386 on {new Date('202310181600').toLocaleString()}</i>
                    </Card.Header>
                    <Card.Body dangerouslySetInnerHTML={{
                        __html: `
                    Finally, the new Mythos layout is here! We've endured a tough layout while testing a ton of features, but we've finally launched
                    a great new redesign that will take Mythos to the next level! We still have plenty of features planned to be introduced over the
                    next few weeks to months and we cannot wait to share more!
                    `
                    }}>
                    </Card.Body>
                    <Card.Footer className="text-end text-light">
                    </Card.Footer>
                </Card>
            </Col>
        </Row>
        <Row className="mb-5">
            <Col>
                <hr className="divider mx-auto" />
            </Col>
        </Row>
    </Container>
}

export default HomePage;