import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { getIdentity, guestIdentity, resetStorageIdentity, setStorageIdentity } from '../services/IdentityService';
import ApiService from '../services/ApiService';

interface IdentityState {
    identity: UmbrielIdentity,
    authenticated: boolean,
    authenticating: boolean,
    authenticate: (email: string, password: string) => Promise<{ success: boolean, message?: string }>,
    register: (email: string, username: string, password: string) => Promise<{ success: boolean, message?: string }>,
    validate: (accessToken?: string) => void,
    resetIdentity: () => void
}

export const IdentityContext = createContext<IdentityState>({
    identity: guestIdentity,
    authenticated: false,
    authenticating: true,
    authenticate: () => Promise.resolve({ success: false }),
    register: () => Promise.resolve({ success: false }),
    validate: () => { },
    resetIdentity: () => { }
});

export const IdentityProvider = ({ children }: { children: React.ReactNode }) => {
    const identityRef = useRef<UmbrielIdentity>(guestIdentity);
    const [authenticated, setAuthenticated] = useState<boolean>(false);
    const [authenticating, setAuthenticating] = useState<boolean>(true);

    const setIdentity = useCallback((identity: UmbrielIdentity) => {
        identityRef.current = identity;
        setStorageIdentity(identity);
        setAuthenticated(true);
        setAuthenticating(false);
    }, []);

    const resetIdentity = useCallback(() => {
        identityRef.current = guestIdentity;
        resetStorageIdentity();
        setAuthenticated(false);
        setAuthenticating(false);
    }, []);

    const authenticate = useCallback(async (email: string, password: string) => {
        setAuthenticating(true);

        let result = {
            success: false,
            message: undefined,
            data: undefined
        }

        try {
            await ApiService.post('/authenticate', {
                email,
                password
            }).then((response) => {
                if (response.success) {
                    setIdentity(response.data);
                } else {
                    console.log(response.message);
                }
            });

            result.success = true;
        } catch (error: any) {
            result.success = false;
            result.message = error.message;
        } finally {
            setAuthenticating(false);
        }

        return result;
    }, [setIdentity, setAuthenticating]);

    const validate = useCallback(async (accessToken?: string) => {
        setAuthenticating(true);

        if (!accessToken)
            accessToken = identityRef.current.accessToken;

        let result = {
            success: false,
            message: undefined,
            data: undefined
        }

        try {
            await ApiService.post('/validate', {
                accessToken
            }).then((response) => {
                if (response.success) {
                    setIdentity(response.data);
                } else {
                    console.log(response.message);
                    resetIdentity();
                }
            });

            result.success = true;
        } catch (error: any) {
            result.success = false;
            result.message = error.message;
        } finally {
            setAuthenticating(false);
        }

        return result;
    }, [resetIdentity, setIdentity, setAuthenticating]);

    const register = useCallback(async (email: string, username: string, password: string) => {
        setAuthenticating(true);

        let result = {
            success: false,
            message: undefined,
            data: undefined
        }

        try {
            await ApiService.post('/register', {
                email,
                username,
                password
            }).then((response) => {
                if (response.success) {
                    setIdentity(response.data);
                } else {
                    console.log(response.message);
                }
            });

            result.success = true;
        } catch (error: any) {
            result.success = false;
            result.message = error.message;
        } finally {
            setAuthenticating(false);
        }

        return result;
    }, [setIdentity, setAuthenticating]);

    useEffect(() => {
        if (!authenticated) {
            setAuthenticating(true);
            getIdentity().then((identity) => {
                if (!identity.accessToken || identity.accessToken.length === 0) throw new Error('No access token found.');
                validate(identity.accessToken);
            }).catch(() => {
                resetIdentity();
            });
        }
    }, [authenticated, resetIdentity, validate]);

    const providerMemo = useMemo(() => {
        return {
            identity: identityRef.current,
            authenticated,
            authenticating,
            authenticate,
            register,
            validate,
            resetIdentity
        };
    }, [authenticate, authenticated, authenticating, register, resetIdentity, validate]);

    return (
        <IdentityContext.Provider value={providerMemo}>
            {children}
        </IdentityContext.Provider>
    )
};

export const useMythosIdentity = () => useContext(IdentityContext);