import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col, Card, Button, Badge } from "react-bootstrap";

import ApiService from "../services/ApiService";
import { getBadge, getProfilePicture } from "../utilities/Helpers";

import icon from '../styles/images/icons/icon.png';
import EndDivider from "../components/common/EndDivider";

const ProfilePage = () => {
    const navigate = useNavigate();
    const { username } = useParams();
    const [profile, setProfile] = useState<Profile>();
    const [myths, setMyths] = useState<[]>();

    useEffect(() => {
        if (username?.toLowerCase() === "system") navigate('/');

        ApiService.get(`/profile/${username}`).then((result) => {
            if (!result.success)
                navigate('/');
            setProfile(result.data);
            let profileId = result.data.id;
            ApiService.get(`/myths/creator/${profileId}`).then((response) => {
                setMyths(response.data);
            });
        });
    }, [navigate, username]);

    const PublishedMyths = () => {
        if (!myths) {
            return <>Loading myths...</>;
        }

        if (myths.length === 0) {
            return <>No published myths</>;
        }

        return (
            <>
                {myths && myths.length > 0 && myths.map((myth: any) => (
                    <Col xs={8} md={3} key={myth.id}>
                        <Card className="text-center">
                            <Card.Body>
                                <img src={icon} alt="Mod" className="rounded" height={100} width={100} />
                                <h5 className="mt-3">{myth.name}</h5>
                                <Row className="g-0 mb-2">
                                    {myth.tags && myth.tags.map((tag: any) => (
                                        <Col key={tag.id}>
                                            <Badge bg="secondary">{tag.name}</Badge>
                                        </Col>
                                    ))}
                                </Row>
                                <Button className="m-btn m-btn-primary w-100" onClick={() => navigate(`/myth/${myth.id}`)}>Learn More</Button>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </>
        );
    }

    return (
        <Container className="pt-4 pb-5 py-md-5">
            <h1 className="text-center header w-75 mx-auto mt-md-3 mb-4 pb-md-3">{username}'s Profile</h1>
            <hr className="large-divider" />
            {!profile && (<>
                <Row className="pt-3 mt-5 w-75 mx-auto">
                    <Col xs={12} className="text-center">
                        <p>Loading Profile...</p>
                    </Col>
                </Row>
            </>)}
            {profile && (<>
                <Row className="mb-5">
                    <Col xs={12} md={3} className="text-center">
                        <div className="profile-picture mx-auto">
                            <img src={getProfilePicture(profile.username)} alt="profile" className="rounded" height={100} width={100} />
                            <h3 className="header text-dark">{profile.username}</h3>
                            {profile.roles && profile.roles.map((role: any) => (
                                <>
                                    <Badge className="me-2 fs-7" key={role.id} bg={getBadge(role.id)}>{role.name}</Badge>
                                </>
                            ))}
                        </div>
                    </Col>
                    <Col xs={12} md={9} className="bg-tan p-3 mt-3 mt-md-0">
                        <h3 className="header">Published Myths</h3>
                        <Row className="px-2">
                            <PublishedMyths />
                        </Row>
                    </Col>
                </Row>
            </>)}
            <EndDivider />
        </Container>
    );
}

export default ProfilePage;