import { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useMythosIdentity } from "../../contexts/IdentityContext";

import icon from '../../styles/images/icons/icon.png';

const LoginModal = () => {
    const { authenticate, authenticating } = useMythosIdentity();

    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string | undefined>();

    const [show, setShow] = useState<boolean>(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const handleLogin = async () => {
        setErrorMessage(undefined);

        if (!email)
            return setErrorMessage('Email is required.');
        if (!password)
            return setErrorMessage('Password is required.');

        authenticate(email, password).then((result) => {
            if (!result.success)
                setErrorMessage(result.message);
        });
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
        setErrorMessage(undefined);
    };

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
        setErrorMessage(undefined);
    };

    return <>
        <Modal show={show} onHide={handleClose} className="dark-modal" size="sm" aria-labelledby="Login Form" centered>
            <Form>
                <Modal.Body className="text-center">
                    <img src={icon} alt="icon" className="w-25 mb-3" />
                    <h3 className="text-light">Mythos Login</h3>
                    <hr className="large-divider" />
                    <div className="text-start">
                        <Form.Group className="mb-3" controlId="Email">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" placeholder="Enter email" onChange={handleEmailChange} autoFocus required />
                            <Form.Control.Feedback type="invalid">
                                Email is required
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="Password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Password" onChange={handlePasswordChange} required />
                            <Form.Control.Feedback type="invalid">
                                Password is required
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="RememberMe">
                            <Form.Check type="checkbox" label="Remember Me" />
                        </Form.Group>
                    </div>
                    {errorMessage && (<p className="text-danger">{errorMessage}</p>)}
                    <hr className="large-divider" />
                    <Row>
                        <Col>
                            <Button className="m-btn m-btn-secondary w-100 text-light" onClick={handleClose}>Cancel</Button>
                        </Col>
                        <Col>
                            <Button className="m-btn m-btn-primary w-100" type="submit" onClick={handleLogin} disabled={authenticating}>{authenticating ? 'Waiting...' : 'Login'}</Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Form>
        </Modal>
        <button onClick={handleShow} className="btn-link text-light">Login</button>
    </>
}

export default LoginModal