import storage from './StorageService';

export const guestIdentity: UmbrielIdentity = {
    username: 'Guest'
};

export const getIdentity = async (): Promise<UmbrielIdentity> => {
    const storageIdentity = await storage.get('identity');
    if (storageIdentity) return JSON.parse(storageIdentity)
    else return guestIdentity;
}

export function setStorageIdentity(identity: UmbrielIdentity) {
    storage.set('identity', JSON.stringify(identity));
}

export function resetStorageIdentity() {
    storage.remove('identity');
}