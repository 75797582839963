import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Badge, Button, Col, Container, Modal, Row, Table } from "react-bootstrap";
import { useMythosIdentity } from "../contexts/IdentityContext";

import ApiService from "../services/ApiService";
import EndDivider from "../components/common/EndDivider";
import { Search, Trash } from "react-bootstrap-icons";

const MyMythsPage = () => {
    const navigate = useNavigate();
    const { authenticated, authenticating } = useMythosIdentity();
    const [loading, setLoading] = useState<boolean>(true);
    const [myths, setMyths] = useState<[]>();
    const [showStatusModal, setShowStatusModal] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

    const updateMyths = useCallback(() => {
        ApiService.get('/private/myths').then((response) => {
            if (response && response.success) {
                setMyths(response.data);
            } else throw new Error(response.message);
        }).catch(console.log).finally(() => {
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        if (!authenticated && authenticating) return;
        if (!authenticated && !authenticating) navigate('/');

        updateMyths();
    }, [authenticated, authenticating, navigate, updateMyths]);

    const MythsTable = () => {
        if (!myths || loading) return <tr><td colSpan={8}>Loading myths...</td></tr>;
        if (myths.length === 0) return <tr><td colSpan={8}>No myths found</td></tr>;
        let results = myths.map((myth: any) => {
            const RequestStatus = () => {
                let latest_request = myth.requests[0];
                if (!latest_request) return <Badge bg="secondary">N/A</Badge>;

                switch (latest_request.status) {
                    case 'Approved':
                        return <Badge bg="success">Approved</Badge>;
                    case 'Rejected':
                        return <Badge bg="danger" onClick={() => setShowStatusModal(true)} className="pointer">Rejected</Badge>
                    case 'Pending':
                    default:
                        return <Badge bg="warning">Pending</Badge>;
                }
            }

            const StatusModal = () => {
                let latest_request = myth.requests[0];
                if (!latest_request) return <></>;

                return (
                    <Modal show={showStatusModal} onHide={() => setShowStatusModal(false)} className="dark-modal" aria-labelledby="Review Myth Form" centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Request Status</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    Status: {latest_request.status}<br />
                                    Reason: {latest_request.reason ?? 'N/A'}<br />
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Modal>
                )
            }

            const DeleteModal = () => {
                const DeleteMyth = () => {
                    ApiService.post('/myths/delete/', { id: myth.id }).then((response) => {
                        if (response && response.success) {
                            updateMyths();
                            setShowDeleteModal(false);
                        }
                    }).catch(console.log);
                }

                return (
                    <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} className="dark-modal" aria-labelledby="Review Myth Form" centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Delete Myth</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    Are you sure you want to delete this myth?
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                                Cancel
                            </Button>
                            <Button variant="danger" onClick={DeleteMyth}>
                                Delete
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )
            }

            return (<tr key={myth.id} className="text-center">
                <td className="d-none d-md-table-cell">{myth.id}</td>
                <td>{myth.name}</td>
                <td className="d-none d-md-table-cell">{myth.latest_version ?? 'N/A'}</td>
                <td className="d-none d-md-table-cell">{myth.game_mode}</td>
                <td>{myth.downloads ?? 0}</td>
                <td>
                    <RequestStatus />
                </td>
                <td>
                    <Button onClick={() => navigate(`/myth/${myth.id}`)} className="m-btn m-btn-info p-1 me-2">
                        <span className="d-none d-md-inline-block">View</span>
                        <span className="d-inline-block d-md-none">
                            <Search />
                        </span>
                    </Button>
                    <Button onClick={() => navigate(`/myth/edit/${myth.id}`)} className="m-btn m-btn-primary p-1 me-2 d-none d-md-inline-block">
                        Edit
                    </Button>
                    <Button onClick={() => setShowDeleteModal(true)} className="m-btn m-btn-danger p-1">
                        <span className="d-none d-md-inline-block">Delete</span>
                        <span className="d-inline-block d-md-none">
                            <Trash />
                        </span>
                    </Button>
                </td>
                <StatusModal />
                <DeleteModal />
            </tr>);
        });

        return <>{results}</>;
    }

    return (
        <Container className="pt-4 pb-5 py-md-5">
            <h1 className="text-center header w-75 mx-auto mt-3 mb-4 pb-3">My Myths</h1>
            <hr className="large-divider" />
            <Row>
                <Col>
                    <Table className="text-center">
                        <thead>
                            <tr>
                                <th className="d-none d-md-table-cell">Myth ID</th>
                                <th><span className="d-none d-md-inline-block">Myth&nbsp;</span>Name</th>
                                <th className="d-none d-md-table-cell">Latest Version</th>
                                <th className="d-none d-md-table-cell">Game Mode</th>
                                <th className="d-none d-md-table-cell">Downloads</th>
                                <th className="d-table-cell d-md-none">DL</th>
                                <th className="d-none d-md-table-cell">Request Status</th>
                                <th className="d-table-cell d-md-none">Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <MythsTable />
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <EndDivider />
        </Container>
    );
}

export default MyMythsPage;