import { Link } from "react-router-dom";
import { Col, Container, Navbar, Row } from "react-bootstrap";

import Identity from "./Identity";

import logo from '../styles/images/mythos_logo.png';
import umbrielLogo from '../styles/images/us-logo.png';
import umbrielIcon from '../styles/images/icons/us-icon.png';
import discordIcon from '../styles/images/icons/discord-white.png';
import githubIcon from '../styles/images/icons/github-white.png';
import minecraftIcon from '../styles/images/icons/minecraft.png';

export const Header = () => (
    <div className="header">
        <Navbar variant="dark" fixed="top" className="text-light subnav">
            <Container fluid>
                <Row className="justify-content-between w-100">
                    <Col xs={6} className="text-start">
                        <a href="https://www.umbrielstudios.com" target="_blank" rel="noreferrer" title="Umbriel Studios" className="me-3 d-none d-md-inline-block">
                            <img src={umbrielIcon} alt="Umbriel Studios" height={32} />
                        </a>
                        <a href="https://discord.gg/QP2ESnWjbr" target="_blank" rel="noreferrer" title="Legends Modding Discord" className="me-3">
                            <img src={discordIcon} alt="Discord" height={32} />
                        </a>
                        <a href="https://github.com/LegendsModding" target="_blank" rel="noreferrer" title="Legends Modding GitHub" className="me-3">
                            <img src={githubIcon} alt="GitHub" height={32} />
                        </a>
                        <a href="https://www.minecraft.net/en-us/about-legends" target="_blank" rel="noreferrer" title="Minecraft Legends" className="me-3">
                            <img src={minecraftIcon} alt="Minecraft Legends" height={32} />
                        </a>
                    </Col>
                    <Col xs={6} className="text-end pe-0">
                        <Identity />
                    </Col>
                </Row>
            </Container>
        </Navbar>
        <Navbar variant="dark" fixed="top" className="text-light mainnav">
            <Row className="justify-content-center w-75 mx-auto fs-5">
                <Col xs={3} className="pt-3 text-center d-none d-md-block">
                    <Row>
                        <Col>
                            <Link to="/">Home</Link>
                        </Col>
                        <Col>
                            <Link to="/myths">Myths</Link>
                        </Col>
                        {/*<Col>
                            <Link to="/Assets">Assets</Link>
                        </Col>*/}
                    </Row>
                </Col>
                <Col xs={12} md={5} className="text-center">
                    <img src={logo} alt="logo" className="w-50 d-none d-md-inline-block" />
                    <Link to="/" className="d-inline-block d-md-none pt-2">
                        <img src={logo} alt="logo" className="w-100" />
                    </Link>
                </Col>
                <Col xs={3} className="pt-3 text-center d-none d-md-block">
                    <Row>
                        {/*<Col>
                            <Link to='/client'>Manager</Link>
                        </Col>*/}
                        <Col>
                            <Link to='/resources'>Resources</Link>
                        </Col>
                        <Col>
                            <Link to="/about">About</Link>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Navbar>
        <Navbar variant="dark" fixed="top" className="text-center text-light d-block d-md-none mobilenav pt-5">
            <Container fluid className="mt-5 pt-1 pt-md-5 bg-dark-grey">
                <Row className="justify-content-center mx-auto pb-2 w-100">
                    <Col xs={4}>
                        <Link to="/myths">Myths</Link>
                    </Col>
                    <Col xs={4}>
                        <Link to='/resources'>Resources</Link>
                    </Col>
                    <Col xs={4}>
                        <Link to="/about">About</Link>
                    </Col>
                </Row>
            </Container>
        </Navbar>
    </div>
)

export const Footer = () => {
    return <div className="w-100 footer">
        <div className="footer-bar"></div>
        <Row className="w-100 mx-auto">
            <Col xs={12} className="text-center">
                <Row className="py-3 justify-content-center">
                    <Col>
                        <a href="https://www.umbrielstudios.com" target="_blank" rel="noreferrer">
                            <img src={umbrielLogo} alt="Umbriel Studios" height={34} />
                        </a>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className="text-light">
                            &copy; {new Date().getFullYear()} All rights reserved.<br />
                            <Link to="/terms">Terms of Service</Link> | <Link to="/privacy">Privacy Policy</Link>
                        </p>
                    </Col>
                </Row>
                <Row className="pb-3 py-md-0">
                    <Col className="text-light fs-8">
                        Not affiliated with Minecraft, Mojang, or Microsoft.
                        Minecraft is a trademark of Mojang Synergies AB.
                        Microsoft is a trademark of Microsoft Corporation.
                    </Col>
                </Row>
            </Col>
        </Row>
    </div>
}