import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

import ApiService from "../services/ApiService";

const VerifyPage = () => {
    const { code } = useParams<{ code: string }>();
    const [validCode, setValidCode] = useState<boolean>(false);
    const [verifying, setVerifying] = useState<boolean>(true);
    const [error, setError] = useState<string>('');

    useEffect(() => {
        if (code) {
            ApiService.post('/verify', {
                code
            }).then((result: any) => {
                if (!result.success) {
                    throw new Error(result.message);
                }
                setValidCode(true);
            }).catch((error: any) => {
                setError(error.message);
            }).finally(() => {
                setVerifying(false);
            });
        }
    }, [code]);

    const VerifyStatus = () => {
        if (verifying) return <p>Verifying...</p>;
        if (!verifying && !validCode) return <p className="text-danger">{error}</p>;
        return <p className="text-success">Thank you for verifying your email address. You may now use Mythos normally.</p>;
    }

    return (
        <Container>
            <Row className="justify-content-center text-center">
                <Col xs={12} md={6}>
                    <h1>Mythos Verification</h1>
                    <div className="dark-bg pt-3 bi-3 fs-4">
                        <VerifyStatus />
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default VerifyPage;