import { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import ApiService from "../../services/ApiService";
import { encodeFileBase64, getMythImage } from "../../utilities/Helpers";

const CreateMythForm = () => {
    const navigate = useNavigate();
    const inputRef = useRef<HTMLInputElement>(null);

    const [loading, setLoading] = useState<boolean>(false);
    const [categories, setCategories] = useState<Category[]>([]);

    const [mythName, setMythName] = useState<string>();
    const [mythGameMode, setMythGameMode] = useState<"Campaign" | "PVP">();
    const [mythCategory, setMythCategory] = useState<number>();
    const [mythShortDescription, setMythShortDescription] = useState<string>();
    const [mythLongDescription, setMythLongDescription] = useState<string>();
    const [mythDiscord, setMythDiscord] = useState<string>();
    const [mythGithub, setMythGithub] = useState<string>();
    const [mythTwitter, setMythTwitter] = useState<string>();
    const [mythYoutube, setMythYoutube] = useState<string>();
    const [mythImages, setMythImages] = useState<MythImage[]>([]);
    const [files, setFiles] = useState<File[]>([]);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        if (e.target.files && e.target.files.length > 0) {
            let image = e.target.files[0];
            encodeFileBase64(image).then((imageHash) => {
                setMythImages([...mythImages, { image_hash: imageHash }]);
                let imageEncoded = new File([image], imageHash, { type: image.type });
                setFiles([...files, imageEncoded]);
            });
        }
    }

    function handleFormSubmit(e: any) {
        e.preventDefault();
    }

    useEffect(() => {
        setLoading(true);
        ApiService.get('/myths/categories').then((response) => {
            setCategories(response.data);
        }).catch(console.log).finally(() => {
            setLoading(false);
        });
    }, []);

    function deleteImage(e: any) {
        e.preventDefault();
        let hash = e.currentTarget.value;
        let updatedImages = mythImages.filter((image: MythImage) => image.image_hash !== hash);
        setMythImages(updatedImages);

        if (files.length > 0) {
            let updatedFiles = files.filter((file: File) => file.name !== hash);
            setFiles(updatedFiles);
        }
    }

    function addImage(e: React.FormEvent<HTMLElement>) {
        e.preventDefault();
        inputRef.current?.click();
    };

    async function handleAddMyth(e: any) {
        e.preventDefault();

        if (!mythName || !mythGameMode || !mythCategory || !mythShortDescription || !mythLongDescription) {
            alert('Please fill out all required fields.');
            return;
        }

        const formData = new FormData();
        formData.append('name', mythName);
        formData.append('game_mode', mythGameMode);
        formData.append('category_id', mythCategory.toString());
        formData.append('short_description', mythShortDescription);
        formData.append('long_description', mythLongDescription);

        if (mythDiscord)
            formData.append('discord_link', mythDiscord);
        if (mythGithub)
            formData.append('github_link', mythGithub);
        if (mythTwitter)
            formData.append('twitter_link', mythTwitter);
        if (mythYoutube)
            formData.append('youtube_link', mythYoutube);

        if (files.length > 0) {
            files.forEach((file: File) => {
                formData.append('images', file);
            });
        }

        let response = await ApiService.post('/myths/add', formData, true);

        if (!response.success)
            throw new Error(`Failed to update: ${response.message}`);

        navigate(`/myth/${response.data.id}`);
    }

    if (loading) return <h4>Loading form...</h4>;

    return (<Form onSubmit={handleFormSubmit}>
        <Row>
            <Col xs={12} md={4}>
                <Form.Group className="mb-3" controlId="mythTitle">
                    <Form.Label>Myth Name</Form.Label>
                    <Form.Control type="text" placeholder="Myth Name" defaultValue={mythName} required onChange={(e: any) => setMythName(e.currentTarget.value)} maxLength={25} />
                </Form.Group>
            </Col>
            <Col xs={12} md={2}>
                <Form.Group className="mb-3" controlId="mythGameMode">
                    <Form.Label>Game Mode</Form.Label>
                    <Form.Select aria-label="Game Mode" defaultValue={mythGameMode} required onChange={(e: any) => setMythGameMode(e.currentTarget.value)}>
                        <option>Select Game Mode</option>
                        <option value="Campaign">Campaign</option>
                        <option value="PVP">PVP</option>
                    </Form.Select>
                </Form.Group>
            </Col>
            <Col xs={12} md={2}>
                <Form.Group className="mb-3" controlId="mythCategory">
                    <Form.Label>Category</Form.Label>
                    <Form.Select aria-label="Category" defaultValue={mythCategory} required onChange={(e: any) => setMythCategory(e.currentTarget.value)}>
                        <option value="0">Select Category</option>
                        {categories && categories.map((category: any) => (
                            <option key={category.id} value={category.id}>{category.name}</option>
                        ))}
                    </Form.Select>
                </Form.Group>
            </Col>
        </Row>
        <Row>
            <Col xs={12} md={6}>
                <Form.Group className="mb-3" controlId="mythShortDescription">
                    <Form.Label>Short Description</Form.Label>
                    <Form.Control as="textarea" rows={3} placeholder="Short Description" required defaultValue={mythShortDescription} onChange={(e: any) => setMythShortDescription(e.currentTarget.value)} maxLength={300} />
                </Form.Group>
            </Col>
        </Row>
        <Row>
            <Col xs={12} md={8}>
                <Form.Group className="mb-3" controlId="mythLongDescription">
                    <Form.Label>Long Description <i>(bbcode formatting accepted)</i></Form.Label>
                    <Form.Control as="textarea" rows={5} placeholder="Long Description" required defaultValue={mythLongDescription} onChange={(e: any) => setMythLongDescription(e.currentTarget.value)} />
                </Form.Group>
            </Col>
        </Row>
        <Row>
            <Col xs={12} md={3}>
                <Form.Group className="mb-3" controlId="mythYoutube">
                    <Form.Label>YouTube Trailer <i>(optional)</i></Form.Label>
                    <Form.Control type="text" placeholder="YouTube" defaultValue={mythYoutube} onChange={(e: any) => setMythYoutube(e.currentTarget.value)} />
                </Form.Group>
            </Col>
            <Col xs={12} md={3}>
                <Form.Group className="mb-3" controlId="mythDiscord">
                    <Form.Label>Discord <i>(optional)</i></Form.Label>
                    <Form.Control type="text" placeholder="Discord" defaultValue={mythDiscord} onChange={(e: any) => setMythDiscord(e.currentTarget.value)} />
                </Form.Group>
            </Col>
            <Col xs={12} md={3}>
                <Form.Group className="mb-3" controlId="mythGithub">
                    <Form.Label>GitHub <i>(optional)</i></Form.Label>
                    <Form.Control type="text" placeholder="GitHub" defaultValue={mythGithub} onChange={(e: any) => setMythGithub(e.currentTarget.value)} />
                </Form.Group>
            </Col>
            <Col xs={12} md={3}>
                <Form.Group className="mb-3" controlId="mythTwitter">
                    <Form.Label>Twitter <i>(optional)</i></Form.Label>
                    <Form.Control type="text" placeholder="Twitter" defaultValue={mythTwitter} onChange={(e: any) => setMythTwitter(e.currentTarget.value)} />
                </Form.Group>
            </Col>
        </Row>
        <Row>
            <h4>Image Gallery</h4>
            {
                mythImages && mythImages.map((image: MythImage) => (
                    <Col key={image.id} xs={12} md={4}>
                        <Card className="mb-3 text-center">
                            <Card.Body>
                                <Card.Img src={
                                    (image.id && getMythImage(image.image_hash))
                                    ||
                                    (image.image_hash)
                                } style={{ maxHeight: 200, width: 'auto' }} />
                            </Card.Body>
                            <Card.Footer className="text-end">
                                <Button className="m-btn m-btn-danger" size="sm" type="button" onClick={deleteImage} value={image.image_hash}>Delete</Button>
                            </Card.Footer>
                        </Card>
                    </Col>
                ))
            }
            {
                (!mythImages || mythImages.length < 3) && (
                    <Col xs={12} md={4}>
                        <Card className="mb-3" style={{ height: 285 }}>
                            <Card.Body>
                                <Form.Group className="h-100 w-100" controlId="editProfileImage">
                                    <Button className="m-btn m-btn-link h-100 w-100 d-block" type="button" onClick={addImage}>Add Image</Button>
                                    <input title="pfp" ref={inputRef} className="d-none" type="file" accept="image/png,image/jpg,image/jpeg,image/gif" onChange={handleFileChange} />
                                </Form.Group>
                            </Card.Body>
                        </Card>
                    </Col>
                )
            }
        </Row>
        <hr className="large-divider" />
        <Row>
            <Col className="text-end">
                <Button className="m-btn m-btn-secondary" type="button" onClick={() => navigate(`/myths`)}>Cancel</Button>{' '}
                <Button className="m-btn m-btn-primary" type="submit" onClick={handleAddMyth}>Submit for Approval</Button>
            </Col>
        </Row>
    </Form>)
}

export default CreateMythForm;