const STATIC_URL = process.env.REACT_APP_STATIC_URL;

class DownloadService {
    getMyth(mythId: number) {
        return `${STATIC_URL}/myths/${mythId}/download`;
    }

    getMythVersion(mythId: number, version: string) {
        return `${STATIC_URL}/myths/${mythId}/download/${version}`;
    }

    getMythByHash(fileHash: string) {
        return `${STATIC_URL}/myths/${fileHash}.zip`;
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new DownloadService();