import { ReactNode } from "react";
import { IdentityProvider } from "../contexts/IdentityContext";

const MythosProvider = (props: { children?: ReactNode }) => {
    return (
        <IdentityProvider>
            {props.children}
        </IdentityProvider>
    )
};

export default MythosProvider;