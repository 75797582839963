import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import ApiService from "../../services/ApiService";

const EditAccountModal = ({ account, callback }: { account: any, callback: (response: any) => void }) => {
    const [email, setEmail] = useState<string>(account.email);
    const [password, setPassword] = useState<string | undefined>();
    const [confirmPassword, setConfirmPassword] = useState<string | undefined>();
    const [errorMessage, setErrorMessage] = useState<string>();
    const [successMessage, setSuccessMessage] = useState<string>();

    async function handleUpdateAccount(e: any) {
        e.preventDefault();

        setSuccessMessage(undefined);
        setErrorMessage(undefined);

        const formData = new FormData();
        formData.append('email', email);
        if (password && confirmPassword) {
            if (password !== confirmPassword) {
                setErrorMessage('Passwords do not match.');
                return;
            }
            formData.append('password', password);
        }

        let response = await ApiService.post('/account/update', formData, true);

        if (!response.success) {
            setErrorMessage(response.message);
            throw new Error(`Failed to update: ${response.message}`);
        }

        callback(response.data);
        setSuccessMessage('Account updated successfully.');
    }

    useEffect(() => {
    }, [account]);

    return (<Container>
        <Row>
            <Col>
                <h2 className="header">Edit Account</h2>
                <hr className="large-divider-alt" />
                <Form onSubmit={handleUpdateAccount}>
                    <Row className="mb-3 w-100 w-md-75">
                        <Col xs={12} md={6} className="mb-3">
                            <Form.Group controlId="Email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" placeholder="Email" defaultValue={account.email} onChange={(e) => setEmail(e.target.value)} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-3 w-100 w-md-75">
                        <Col xs={12} md={6} className="mb-3">
                            <Form.Group controlId="Password">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                            <Form.Group controlId="ConfirmPassword">
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control type="password" placeholder="Confirm Password" onChange={(e) => setConfirmPassword(e.target.value)} />
                            </Form.Group>
                        </Col>
                    </Row>
                    {errorMessage && <p className="text-danger">{errorMessage}</p>}
                    {successMessage && <p className="text-success">{successMessage}</p>}
                    <hr className="large-divider-alt" />
                    <Row>
                        <Col>
                            <Button className="m-btn m-btn-primary" onClick={handleUpdateAccount}>Save Changes</Button>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>
    </Container >)
}

export default EditAccountModal;