import { useState, useEffect } from "react";
import ApiService from "../../services/AdminApiService";
import { Col, Container, Row, Table } from "react-bootstrap";

const AdminLogs = () => {
    const [logs, setLogs] = useState<Log[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        ApiService.get('/logs').then((response: any) => {
            if (!response.success)
                throw new Error(response.message);
            setLogs(response.data);
        }).catch(console.log).finally(() => setLoading(false));
    }, []);

    const Logs = () => {
        if (loading) return <tr><td colSpan={6}>Loading logs...</td></tr>;
        if (!logs || logs.length === 0) return <tr><td colSpan={6}>No logs found</td></tr>
        let results = logs.map(log => (
            <tr key={log.id}>
                <td>{log.id}</td>
                <td>{log.account.username}</td>
                <td>{log.action}</td>
                <td>{log.oldValue}</td>
                <td>{log.newValue}</td>
                <td>{new Date(log.createdAt).toLocaleString()}</td>
            </tr>
        ));

        return <>{results}</>
    }

    return (
        <Container>
            <Row>
                <Col>
                    <h1>Logs</h1>
                    <hr className="large-divider" />
                    <div style={{ overflow: "auto", height: 400 }}>
                        <Table className="dark-bg py-3 px-0 bi-3">
                            <thead>
                                <tr>
                                    <th>Log ID</th>
                                    <th>Account</th>
                                    <th>Action</th>
                                    <th>Old Value</th>
                                    <th>New Value</th>
                                    <th>Timestamp</th>
                                </tr>
                            </thead>
                            <tbody>
                                <Logs />
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default AdminLogs;