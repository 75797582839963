import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Table, Button } from "react-bootstrap";
import { CursorFill, DashCircle } from "react-bootstrap-icons";

import AdminApiService from "../../services/AdminApiService";

const AdminMyths = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [myths, setMyths] = useState<any[]>([]);

    useEffect(() => {
        setLoading(true);
        AdminApiService.get('/myths').then((response) => {
            setMyths(response.data);
        }).catch(console.log).finally(() => {
            setLoading(false);
        });
    }, []);

    const Myths = () => {
        if (loading) return <tr><td colSpan={9}>Loading myths...</td></tr>;
        if (!myths || myths.length === 0) return <tr><td colSpan={9}>No myths found</td></tr>
        let results = myths.map(myth => {
            return (
                <tr key={myth.id} className="text-center">
                    <td>{myth.id}</td>
                    <td>{myth.name}</td>
                    <td>
                        <Link to={`/profile/${myth.creator.username}`}>{myth.creator.username}</Link>
                    </td>
                    <td>{myth.game_mode}</td>
                    <td>{myth.category}</td>
                    <td>{(myth.release_date && new Date(myth.release_date).toLocaleDateString()) || 'N/A'}</td>
                    <td>{myth.latest_version ?? 'N/A'}</td>
                    <td>{myth.approved ? "Yes" : "No"}</td>
                    <td>
                        <Row className="justify-content-center g-2">
                            <Col>
                                <Button className="m-btn m-btn-info me-2" size={"sm"} title="View" onClick={() => window.open(`/myth/${myth.id}`, "_blank")}>
                                    <CursorFill />
                                </Button>
                                <Button className="m-btn m-btn-danger" size={"sm"} title="Disable">
                                    <DashCircle />
                                </Button>
                            </Col>
                        </Row>
                    </td>
                </tr>
            )
        });

        return <>{results}</>;
    }

    return (
        <Container>
            <Row>
                <Col>
                    <h1>Myths</h1>
                    <hr className="large-divider" />
                    <div style={{ overflow: "auto", height: 400 }}>
                        <Table className="dark-bg py-3 px-0 bi-3">
                            <thead>
                                <tr className="text-center">
                                    <th>ID</th>
                                    <th>Name</th>
                                    <th>Creator</th>
                                    <th>Game Mode</th>
                                    <th>Category</th>
                                    <th>Release Date</th>
                                    <th>Latest Version</th>
                                    <th>Approved</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <Myths />
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default AdminMyths;