import { useNavigate } from "react-router-dom";
import { useMythosIdentity } from "../contexts/IdentityContext";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { useEffect, useState } from "react";

import ApiService from "../services/ApiService";

import EditAccountSetting from "../components/account/EditAccountSetting";
import EditProfileSetting from "../components/account/EditProfileSetting";
import { AccountRoles } from "../utilities/enums";
import EndDivider from "../components/common/EndDivider";

const SettingsPage = () => {
    const navigate = useNavigate();
    const { authenticated, authenticating, identity, validate } = useMythosIdentity();
    const [account, setAccount] = useState<any>();

    useEffect(() => {
        if (!authenticated && authenticating) return;
        if (!authenticated && !authenticating) navigate('/');
        if (authenticated) {
            if (!identity.roles?.find(role => role.id === AccountRoles.Verified)) navigate('/');

            ApiService.get(`/account/settings`, true).then((result) => {
                if (!result.success)
                    console.log(result.message);
                setAccount(result.data);
            });
        }
    }, [authenticated, authenticating, identity, navigate]);

    const updateAccountChanges = (response: any) => {
        let accessToken = response.accessToken.toString();
        validate(accessToken);
    }

    return <Container className="pt-4 pb-5 py-md-5">
        <h1 className="text-center header w-75 mx-auto mt-3 mb-4 pb-3">User Settings</h1>
        <hr className="large-divider" />
        <Row className="pt-3 mb-5 mb-md-0">
            {!account && (<>
                <Col className="text-center">
                    <p>Loading Account...</p>
                </Col>
            </>)}
            {account && (<>
                <Tab.Container defaultActiveKey="edit-profile">
                    <Nav as="ul" className="text-center">
                        <Nav.Item as="li">
                            <Nav.Link eventKey="edit-profile" className="m-btn-tab">Profile</Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Nav.Link eventKey="edit-account" className="m-btn-tab">Account</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content className="py-3 bg-tan">
                        <Tab.Pane eventKey="edit-profile">
                            <EditProfileSetting account={account} callback={updateAccountChanges} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="edit-account">
                            <EditAccountSetting account={account} callback={updateAccountChanges} />
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </>)}
        </Row>
        <EndDivider />
    </Container>
}

export default SettingsPage;