import ApiService from "./ApiService";

class FeedService {
    getTwitterFeed() {
        return fetch('https://rss.app/feeds/v1.1/x993EBCWmAO4MRgE.json').then(response => response.json());
    }

    async getSteamNewsFeed() {
        let appId = 1928870;
        let feed = [];

        let result = await ApiService.get(`/feed/steam/${appId}`);
        if (result.success) feed = result.data.filter((f: any) => f.feed_type === 1);

        return feed;
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new FeedService();