import { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { encodeFileBase64, getMythImage } from "../../utilities/Helpers";

import ApiService from "../../services/ApiService";
import DownloadService from "../../services/DownloadService";
import EditVerionModal from "../modals/EditVersionModal";
import BBCodeTextbox from "../common/BBCodeTextbox";

const EditMythForm = ({ myth }: { myth: Myth }) => {
    const navigate = useNavigate();
    const imageInputRef = useRef<HTMLInputElement>(null);
    const versionInputRef = useRef<HTMLInputElement>(null);

    const [loading, setLoading] = useState<boolean>(false);
    const [categories, setCategories] = useState<Category[]>([]);

    const [mythName, setMythName] = useState<string>(myth.name);
    const [mythGameMode, setMythGameMode] = useState<"Campaign" | "PVP">(myth.game_mode);
    const [mythCategory, setMythCategory] = useState<number>(myth.category.id);
    const [mythShortDescription, setMythShortDescription] = useState<string>(myth.short_description);
    const [mythLongDescription, setMythLongDescription] = useState<string>(myth.long_description);
    const [mythDiscord, setMythDiscord] = useState<string>(myth.discord_link);
    const [mythGithub, setMythGithub] = useState<string>(myth.github_link);
    const [mythTwitter, setMythTwitter] = useState<string>(myth.twitter_link);
    const [mythYoutube, setMythYoutube] = useState<string>(myth.youtube_link);
    const [mythImages, setMythImages] = useState<MythImage[]>(myth.images);
    const [images, setImages] = useState<File[]>([]);
    const [mythVersions, setMythVersions] = useState<MythVersion[]>(myth.versions);
    const [newVersion, setNewVersion] = useState<File>();
    const [newVersionName, setNewVersionName] = useState<string>();

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        if (e.target.files && e.target.files.length > 0) {
            let image = e.target.files[0];
            encodeFileBase64(image).then((imageHash) => {
                setMythImages([...mythImages, { image_hash: imageHash }]);
                setImages([...images, image]);
            });
        }
    }

    const handleVersionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        if (e.target.files && e.target.files.length > 0) {
            let versionFile = e.target.files[0];
            setMythVersions([...mythVersions, { version: newVersionName ?? 'new_version', file_hash: versionFile.name, upload_date: new Date().toLocaleDateString() }]);
            setNewVersion(versionFile);
        }
    }

    function handleFormSubmit(e: any) {
        e.preventDefault();
    }

    useEffect(() => {
        setLoading(true);
        ApiService.get('/myths/categories').then((response) => {
            setCategories(response.data);
        }).catch(console.log).finally(() => {
            setLoading(false);
        });
    }, []);

    function deleteImage(e: any) {
        e.preventDefault();
        let hash = e.currentTarget.value;
        let updatedImages = mythImages.filter((image: MythImage) => image.image_hash !== hash);
        setMythImages(updatedImages);

        if (images.length > 0) {
            let updatedFiles = images.filter((file: File) => file.name !== hash);
            setImages(updatedFiles);
        }
    }

    function addImage(e: React.FormEvent<HTMLElement>) {
        e.preventDefault();
        imageInputRef.current?.click();
    };

    function deleteVersion(e: any) {
        e.preventDefault();
        let hash = e.currentTarget.value;
        let updatedVersions = mythVersions.filter((version: MythVersion) => version.file_hash !== hash);
        setMythVersions(updatedVersions);

        if (newVersion && newVersion.name === hash) {
            setNewVersion(undefined);
        }
    }

    function editVersion(id: number, newVersion: string) {
        let updatedVersions = mythVersions.map((version: MythVersion) => {
            if (version.id === id) {
                version.version = newVersion;
            }
            return version;
        });
        setMythVersions(updatedVersions);
    }

    function addVersion(e: React.FormEvent<HTMLElement>) {
        e.preventDefault();
        versionInputRef.current?.click();
    }

    function downloadVersion(e: any) {
        e.preventDefault();
        let downloadUrl = DownloadService.getMythByHash(e.currentTarget.value);
        window.open(downloadUrl, '_blank');
    }

    async function handleUpdateMyth(e: any) {
        e.preventDefault();

        const formData = new FormData();
        formData.append('id', myth.id.toString());
        formData.append('name', mythName);
        formData.append('game_mode', mythGameMode);
        formData.append('category_id', mythCategory.toString());
        formData.append('short_description', mythShortDescription);
        formData.append('long_description', mythLongDescription);
        formData.append('discord_link', mythDiscord);
        formData.append('github_link', mythGithub);
        formData.append('twitter_link', mythTwitter);
        formData.append('youtube_link', mythYoutube);
        formData.append('images', JSON.stringify(mythImages));
        formData.append('versions', JSON.stringify(mythVersions));

        if (images.length > 0) {
            images.forEach((file) => {
                formData.append('new_images', file);
            });
        }

        if (newVersion && newVersionName) {
            formData.append('new_version', newVersion);
            formData.append('new_version_name', newVersionName);
        }

        let url = myth.approved ? '/myths/update' : '/myths/resubmit';
        let response = await ApiService.post(url, formData, true);

        if (!response.success)
            throw new Error(`Failed to update: ${response.message}`);

        navigate(`/myth/${myth.id}`);
    }

    if (loading) return <h4>Populating fields...</h4>;

    return (<Form onSubmit={handleFormSubmit}>
        <Row>
            <Col xs={12} md={4}>
                <Form.Group className="mb-3" controlId="mythTitle">
                    <Form.Label>Myth Name</Form.Label>
                    <Form.Control type="text" placeholder="Myth Name" defaultValue={mythName} required onChange={(e: any) => setMythName(e.currentTarget.value)} maxLength={25} />
                </Form.Group>
            </Col>
            <Col xs={12} md={2}>
                <Form.Group className="mb-3" controlId="mythGameMode">
                    <Form.Label>Game Mode</Form.Label>
                    <Form.Select aria-label="Game Mode" defaultValue={mythGameMode} required onChange={(e: any) => setMythGameMode(e.currentTarget.value)}>
                        <option>Select Game Mode</option>
                        <option value="Campaign">Campaign</option>
                        <option value="PVP">PVP</option>
                    </Form.Select>
                </Form.Group>
            </Col>
            <Col xs={12} md={2}>
                <Form.Group className="mb-3" controlId="mythCategory">
                    <Form.Label>Category</Form.Label>
                    <Form.Select aria-label="Category" defaultValue={mythCategory} required onChange={(e: any) => setMythCategory(e.currentTarget.value)}>
                        <option value="0">Select Category</option>
                        {categories && categories.map((category: any) => (
                            <option key={category.id} value={category.id}>{category.name}</option>
                        ))}
                    </Form.Select>
                </Form.Group>
            </Col>
        </Row>
        <Row>
            <Col xs={12} md={6}>
                <Form.Group className="mb-3" controlId="mythShortDescription">
                    <Form.Label>Short Description</Form.Label>
                    <Form.Control as="textarea" rows={3} placeholder="Short Description" required defaultValue={mythShortDescription ?? null} onChange={(e: any) => setMythShortDescription(e.currentTarget.value)} maxLength={300} />
                </Form.Group>
            </Col>
        </Row>
        <Row>
            <Col xs={12} md={8}>
                <Form.Group className="mb-3" controlId="mythLongDescription">
                    <Form.Label>Long Description</Form.Label>
                    <BBCodeTextbox defaultValue={mythLongDescription ?? null} onChange={(e: any) => setMythLongDescription(e.currentTarget.value)} />
                </Form.Group>
            </Col>
        </Row>
        <Row>
            <Col xs={12} md={3}>
                <Form.Group className="mb-3" controlId="mythYoutube">
                    <Form.Label>YouTube Trailer <i>(optional)</i></Form.Label>
                    <Form.Control type="text" placeholder="YouTube" defaultValue={mythYoutube ?? null} onChange={(e: any) => setMythYoutube(e.currentTarget.value)} />
                </Form.Group>
            </Col>
            <Col xs={12} md={3}>
                <Form.Group className="mb-3" controlId="mythDiscord">
                    <Form.Label>Discord <i>(optional)</i></Form.Label>
                    <Form.Control type="text" placeholder="Discord" defaultValue={mythDiscord ?? null} onChange={(e: any) => setMythDiscord(e.currentTarget.value)} />
                </Form.Group>
            </Col>
            <Col xs={12} md={3}>
                <Form.Group className="mb-3" controlId="mythGithub">
                    <Form.Label>GitHub <i>(optional)</i></Form.Label>
                    <Form.Control type="text" placeholder="GitHub" defaultValue={mythGithub ?? null} onChange={(e: any) => setMythGithub(e.currentTarget.value)} />
                </Form.Group>
            </Col>
            <Col xs={12} md={3}>
                <Form.Group className="mb-3" controlId="mythTwitter">
                    <Form.Label>Twitter <i>(optional)</i></Form.Label>
                    <Form.Control type="text" placeholder="Twitter" defaultValue={mythTwitter} onChange={(e: any) => setMythTwitter(e.currentTarget.value)} />
                </Form.Group>
            </Col>
        </Row>
        <Row>
            <h4>Image Gallery</h4>
            {
                mythImages && mythImages.map((image: MythImage) => (
                    <Col key={image.id} xs={12} md={4}>
                        <Card className="mb-3 text-center">
                            <Card.Body>
                                <Card.Img src={
                                    (image.id && getMythImage(image.image_hash))
                                    ||
                                    (image.image_hash)
                                } style={{ maxHeight: 200, width: 'auto' }} />
                            </Card.Body>
                            <Card.Footer className="text-end">
                                <Button className="m-btn m-btn-danger" size="sm" type="button" onClick={deleteImage} value={image.image_hash}>Delete</Button>
                            </Card.Footer>
                        </Card>
                    </Col>
                ))
            }
            {
                (!mythImages || mythImages.length < 3) && (
                    <Col xs={12} md={4}>
                        <Card className="mb-3" style={{ height: 285 }}>
                            <Card.Body>
                                <Form.Group className="h-100 w-100" controlId="addMythImage">
                                    <Button className="m-btn m-btn-link h-100 w-100 d-block" type="button" onClick={addImage}>Add Image</Button>
                                    <input title="pfp" ref={imageInputRef} className="d-none" type="file" accept="image/png,image/jpg,image/jpeg,image/gif" onChange={handleImageChange} />
                                </Form.Group>
                            </Card.Body>
                        </Card>
                    </Col>
                )
            }
        </Row>
        <Row>
            <h4>Versions</h4>
            <Col xs={12} md={8}>
                <Table className="text-center">
                    <thead>
                        <tr>
                            <th>Version</th>
                            <th>Hash</th>
                            <th>Upload Date</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            mythVersions && mythVersions.map((version: MythVersion) => (
                                <tr key={version.id}>
                                    <td>
                                        {version.version}
                                    </td>
                                    <td>
                                        {version.file_hash}
                                    </td>
                                    <td>
                                        {new Date(version.upload_date).toLocaleDateString()}
                                    </td>
                                    <td>
                                        <EditVerionModal currentVersion={version.version} versionId={version.id!} callback={editVersion} />
                                        <Button className="m-btn m-btn-secondary me-2" size={"sm"} type="button" value={version.file_hash} onClick={downloadVersion}>Download</Button>
                                        <Button className="m-btn m-btn-danger" size={"sm"} type="button" value={version.file_hash} onClick={deleteVersion}>Delete</Button>
                                    </td>
                                </tr>
                            ))
                        }
                        <tr>
                            <td colSpan={3}>
                                <Form.Control type="text" placeholder="Version" onChange={(e: any) => setNewVersionName(e.currentTarget.value)} maxLength={10} />
                            </td>
                            <td>
                                <Form.Group className="h-100 w-100" controlId="addVersion">
                                    <Button className="m-btn m-btn-link w-100" type="button" onClick={addVersion}>Add Version</Button>
                                    <input title="verion" ref={versionInputRef} className="d-none" type="file" accept=".zip" onChange={handleVersionChange} />
                                </Form.Group>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Col>
        </Row>
        <hr className="large-divider" />
        <Row>
            <Col className="text-end">
                <Button className="m-btn m-btn-secondary" type="button" onClick={() => navigate(`/myth/${myth.id}`)}>Cancel</Button>{' '}
                <Button className="m-btn m-btn-primary" type="submit" onClick={handleUpdateMyth}>Save Changes</Button>
            </Col>
        </Row>
    </Form>)
}

export default EditMythForm;