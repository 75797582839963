import { useRef } from "react";
import { Button, ButtonGroup, Form } from "react-bootstrap";
import { Code, Image, Justify, JustifyLeft, JustifyRight, Link, ListOl, ListUl, PaintBucket, Quote, TextCenter } from "react-bootstrap-icons";

const BBCodeTextbox = ({ defaultValue, onChange }: { defaultValue: any, onChange: any }) => {
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const insertTag = (e: any) => {
        let tag = e.currentTarget.value;
        const { current } = textAreaRef;
        if (!current) return;

        const start = current.selectionStart;
        const end = current.selectionEnd;

        const before = current?.value.substring(0, start);
        const selectedText = current?.value.substring(start, end);
        const after = current?.value.substring(end);

        let closingTag = tag;

        if (tag === 'url') {
            tag = `${tag}=${prompt('Enter URL')}`;
        } else if (tag === 'img') {
            tag = `${tag}=${prompt('Enter Image URL')}`;
        } else if (tag === 'color') {
            tag = `${tag}=${prompt('Enter Color')}`;
        } else if (tag === 'list') {
            tag = `${tag}=${prompt('Enter List Type (a, 1)')}`;
        } else if (tag === 'olist') {
            tag = `${tag}=${prompt('Enter List Type (a, 1)')}`;
        }

        if (tag.startsWith('size')) {
            closingTag = 'size';
        }

        const newText = `${before}[${tag}]${selectedText}[/${closingTag}]${after}`;

        current.value = newText;

        current.selectionStart = current.selectionEnd = end + tag.length * 2 + 4;
    }

    return (<>
        <ButtonGroup className="mb-1 ms-2">
            <Button size={"sm"} value={'b'} onClick={insertTag} className='m-btn m-btn-primary' title="Bold"><b>B</b></Button>
            <Button size={"sm"} value={'u'} onClick={insertTag} className='m-btn m-btn-primary' title="Underline"><u>U</u></Button>
            <Button size={"sm"} value={'i'} onClick={insertTag} className='m-btn m-btn-primary' title="Italic"><i>I</i></Button>
            <Button size={"sm"} value={'s'} onClick={insertTag} className='m-btn m-btn-primary' title="Strikethrough"><s>S</s></Button>
        </ButtonGroup>
        <ButtonGroup className="mb-1 ms-2">
            <Button size={"sm"} value={'h1'} onClick={insertTag} className='m-btn m-btn-primary' title="Header 1">H1</Button>
            <Button size={"sm"} value={'h2'} onClick={insertTag} className='m-btn m-btn-primary' title="Header 2">H2</Button>
            <Button size={"sm"} value={'h3'} onClick={insertTag} className='m-btn m-btn-primary' title="Header 3">H3</Button>
        </ButtonGroup>
        <ButtonGroup className="mb-1 ms-2">
            <Button size={"sm"} value={'left'} onClick={insertTag} className='m-btn m-btn-primary' title="Align Left"><JustifyLeft /></Button>
            <Button size={"sm"} value={'center'} onClick={insertTag} className='m-btn m-btn-primary' title="Align Center"><TextCenter /></Button>
            <Button size={"sm"} value={'right'} onClick={insertTag} className='m-btn m-btn-primary' title="Align Right"><JustifyRight /></Button>
            <Button size={"sm"} value={'justify'} onClick={insertTag} className='m-btn m-btn-primary' title="Justify"><Justify /></Button>
        </ButtonGroup>
        <ButtonGroup className="mb-1 ms-2">
            <Button size={"sm"} value={'url'} onClick={insertTag} className='m-btn m-btn-primary' title="URL"><Link /></Button>
            <Button size={"sm"} value={'img'} onClick={insertTag} className='m-btn m-btn-primary' title="Image"><Image /></Button>
        </ButtonGroup>
        <ButtonGroup className="mb-1 ms-2">
            <Button size={"sm"} value={'quote'} onClick={insertTag} className='m-btn m-btn-primary' title="Quote"><Quote /></Button>
            <Button size={"sm"} value={'code'} onClick={insertTag} className='m-btn m-btn-primary' title="Code"><Code /></Button>
        </ButtonGroup>
        <ButtonGroup className="mb-1 ms-2">
            <Button size={"sm"} value={'list'} onClick={insertTag} className='m-btn m-btn-primary' title="List"><ListUl /></Button>
            <Button size={"sm"} value={'olist'} onClick={insertTag} className='m-btn m-btn-primary' title="Ordered List"><ListOl /></Button>
        </ButtonGroup>
        <ButtonGroup className="mb-1 ms-2">
            <Button size={"sm"} value={'color'} onClick={insertTag} className='m-btn m-btn-primary' title="Color"><PaintBucket /></Button>
        </ButtonGroup>
        <ButtonGroup className="mb-1 ms-2">
            <Button size={"sm"} value={'size=10'} onClick={insertTag} className='m-btn m-btn-primary' title="Size">
                <span style={{ fontSize: '10px' }}>A</span>
            </Button>
            <Button size={"sm"} value={'size=12'} onClick={insertTag} className='m-btn m-btn-primary' title="Size">
                <span style={{ fontSize: '12px' }}>A</span>
            </Button>
            <Button size={"sm"} value={'size=14'} onClick={insertTag} className='m-btn m-btn-primary' title="Size">
                <span style={{ fontSize: '14px' }}>A</span>
            </Button>
        </ButtonGroup>
        <Form.Control as={'textarea'} ref={textAreaRef} rows={5} placeholder="Long Description" required defaultValue={defaultValue} onChange={onChange} />
    </>);
};

export default BBCodeTextbox;