import { useState } from "react";
import { Badge, Button, Col, Form, Modal, Row } from "react-bootstrap";
import { PencilSquare } from "react-bootstrap-icons";
import AdminApiService from "../../../services/AdminApiService";
import { getBadge, getProfilePicture } from "../../../utilities/Helpers";
import { AccountRoles } from "../../../utilities/enums";

const EditUserModal = ({ account, roles }: { account: Account, roles: Role[] }) => {
    const [username, setUsername] = useState<string>(account.username);
    const [email, setEmail] = useState<string>(account.email);
    const [preview] = useState<string>(getProfilePicture(account.username));
    const [errorMessage, setErrorMessage] = useState<string>();
    const [successMessage, setSuccessMessage] = useState<string>();
    const [show, setShow] = useState<boolean>(false);
    const [selectedRoles, setSelectedRoles] = useState<number[]>([]);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    async function handleUpdateProfile(e: any) {
        e.preventDefault();

        setSuccessMessage(undefined);
        setErrorMessage(undefined);

        const formData = new FormData();
        formData.append('id', account.id.toString());
        formData.append('email', email);
        formData.append('username', username);
        formData.append('roles', selectedRoles.join(','));

        AdminApiService.post('/account/update', formData, true)
            .then((response) => {
                if (!response.success) {
                    throw new Error(response.message);
                } else setSuccessMessage('Profile updated successfully.');
            }).catch((error) => {
                setErrorMessage(error.message);
                console.log(`Failed to update user ${error.message}`);
            });
    }

    function selectRoles(e: any) {
        let id = parseInt(e.currentTarget.value);
        let roles = [...selectedRoles];
        if (e.currentTarget.checked) {
            roles.push(id);
        } else {
            let index = roles.indexOf(id);
            if (index > -1) {
                roles.splice(index, 1);
            }
        }
        setSelectedRoles(roles);
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} className="dark-modal" aria-labelledby="Edit Profile Form" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Edit User</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleUpdateProfile}>
                    <Modal.Body>
                        <Row className="mb-3 w-100 w-md-75">
                            <Col xs={12} md={6} className="mb-3">
                                <Form.Group controlId="Username" className="mb-3">
                                    <Form.Label>Username</Form.Label>
                                    <Form.Control type="text" placeholder="Username" defaultValue={account.username} onChange={(e) => setUsername(e.target.value)} autoFocus />
                                </Form.Group>
                                <Form.Group controlId="Email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" placeholder="Email" defaultValue={account.email} onChange={(e) => setEmail(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Row>
                                    <span>Profile Picture</span>
                                    <Col className="">
                                        <img src={preview} alt="pfp" height={128} width={128} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="mb-3 w-100 w-md-75">
                            <Col xs={12} md={6} className="mb-3">
                                <Form.Group controlId="Roles">
                                    <Form.Label>Roles</Form.Label>
                                    {
                                        roles.map(role => (
                                            <Form.Check
                                                id="roles"
                                                key={role.id}
                                                type="checkbox"
                                                label={<Badge bg={getBadge(role.id)} className="me-2">{role.name}</Badge>}
                                                defaultChecked={account.roles.find(r => r.id === role.id) !== undefined}
                                                disabled={role.id === AccountRoles.Unverified || role.id === AccountRoles.Verified}
                                                onChange={selectRoles}
                                                value={role.id}
                                            />
                                        ))
                                    }
                                </Form.Group>
                            </Col>
                        </Row>
                        {errorMessage && <p className="text-danger">{errorMessage}</p>}
                        {successMessage && <p className="text-success">{successMessage}</p>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="m-btn m-btn-secondary" onClick={handleClose}>Cancel</Button>
                        <Button className="m-btn m-btn-primary" type="submit">
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <Button className="m-btn m-btn-primary w-100" size={"sm"} onClick={handleShow}>
                <PencilSquare />
            </Button>
        </>
    );
}

export default EditUserModal;