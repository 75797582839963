import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { useMythosIdentity } from "../contexts/IdentityContext";

import ApiService from "../services/ApiService";
import EditMythForm from "../components/forms/EditMyth";
import EndDivider from "../components/common/EndDivider";

const EditMythPage = () => {
    const { mythId } = useParams();
    const navigate = useNavigate();
    const { identity, authenticating, authenticated } = useMythosIdentity();
    const [loading, setLoading] = useState<boolean>(false);
    const [myth, setMyth] = useState<Myth>();

    useEffect(() => {
        if (!authenticated && authenticating) return;
        if (!authenticated && !authenticating) navigate('/');

        setLoading(true);
        ApiService.get(`/private/myth/${mythId}`).then((response) => {
            if (!response.success)
                throw new Error(response.message);

            if (!authenticated || (authenticated && identity.id !== response.data.myth.creator.id)) {
                throw new Error("You do not have permission to edit this Myth");
            }
            setMyth(response.data.myth);
        }).catch((error: any) => {
            console.log(error.message);
            navigate('/');
        }).finally(() => {
            setLoading(false);
        });
    }, [authenticated, authenticating, identity, mythId, navigate]);

    return (<>
        <Container className="py-5">
            <div className="text-center header w-75 mx-auto mt-3 mb-4 pb-3">
                <h1>Edit Myth</h1>
                <h5>ID: {mythId}</h5>
            </div>
            <hr className="large-divider" />
            {authenticating && <h4>Validating ownership data...</h4>}
            {!authenticating && authenticated && identity.id === myth?.creator.id && (<>
                {loading && <h4>Loading Myth data...</h4>}
                {!loading && myth && <EditMythForm myth={myth} />}
            </>
            )}
            <EndDivider />
        </Container>
    </>)
}

export default EditMythPage;