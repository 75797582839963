export enum AccountRoles {
    Unverified,
    Verified,
    MythCreator,
    MythosModerator,
    MythosAdministrator,
    PatreonSupporter,
    DiscordBooster,
    BlackBirdInteractive,
    MojangStaff
}

export enum LogAction {
    AccountCreated = 'AccountCreated',
    AccountVerified = 'AccountVerified',
    AccountUnverified = 'AccountUnverified',
    AccountDeleted = 'AccountDeleted',
    AccountRestored = 'AccountRestored',
    AccountRoleAdded = 'AccountRoleAdded',
    AccountRoleRemoved = 'AccountRoleRemoved',
    AccountPasswordReset = 'AccountPasswordReset',
    AccountEmailChanged = 'AccountEmailChanged',
    AccountUsernameChanged = 'AccountUsernameChanged',
    AccountProfileImageChanged = 'AccountProfileImageChanged',
    AccountProfileImageRemoved = 'AccountProfileImageRemoved',
    AccountProfileImageRestored = 'AccountProfileImageRestored',
    AccountProfileImageDeleted = 'AccountProfileImageDeleted',

    MythCreated = 'MythCreated',
    MythDeleted = 'MythDeleted',
    MythRestored = 'MythRestored',
    MythEdited = 'MythEdited',
    MythImageAdded = 'MythImageAdded',
    MythImageRemoved = 'MythImageRemoved',
    MythImageDeleted = 'MythImageDeleted',

    RoleCreated = 'RoleCreated',
    RoleDeleted = 'RoleDeleted',
    RoleEdited = 'RoleEdited',
}