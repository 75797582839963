import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";

import CreateMythForm from "../components/forms/CreateMyth";
import { useMythosIdentity } from "../contexts/IdentityContext";
import EndDivider from "../components/common/EndDivider";

const CreateMythPage = () => {
    const navigate = useNavigate();
    const { identity, authenticating, authenticated } = useMythosIdentity();

    useEffect(() => {
        if (!authenticated && authenticating) return;
        if (!authenticated && !authenticating) navigate('/');
    }, [authenticated, authenticating, identity, navigate]);

    return (<>
        <Container className="py-5">
            <h1 className="text-center header w-75 mx-auto mt-3 mb-4 pb-3">Create Myth</h1>
            <hr className="large-divider" />
            {authenticating && <h4>Validating ownership data...</h4>}
            {!authenticating && authenticated && (<>
                <CreateMythForm />
            </>
            )}
            <EndDivider />
        </Container>
    </>)
}

export default CreateMythPage;