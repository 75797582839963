import { Badge, Card, Col, Container, Row, Table } from "react-bootstrap";

const ResourcesPage = () => {
    return <Container className="pt-4 pb-5 py-md-5">
        <h1 className="text-center header w-75 mx-auto mt-3 mb-4 pb-3">Modding Resources</h1>
        <hr className="large-divider my-3" />
        <Row>
            <Col>
                <p className="fs-5">
                    Minecraft Legends is a game that was built using a heavily modified Minecraft Bedrock engine, specifically designed to be moddable.
                    Blackbird Interactive, the development team for Minecraft Legends, has made it a primary goal to make the game as moddable as possible.
                    This page contains many tools, documentation, and other resources that are official and community-made to make modding easier for everyone.
                    Please reach out to us on Discord if you have any questions or concerns regarding any links on this page.
                </p>
            </Col>
        </Row>
        <hr className="large-divider mt-2 mb-4" />
        <Row className="justify-content-center d-none d-md-block">
            <Col xs={12}>
                <Table striped bordered hover variant="dark" className="text-center">
                    <thead>
                        <tr>
                            <th>Resource</th>
                            <th>Author</th>
                            <th>Description</th>
                            <th>Type</th>
                            <th>Links</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>FbxConverter</td>
                            <td>LukeFZ</td>
                            <td>Tool to convert FBX to and from the Legends model format.</td>
                            <td>
                                <Badge bg="mythos">Community Tool</Badge>
                            </td>
                            <td>
                                <a href="https://github.com/LegendsModding/FbxConverter" target="_blank" rel="noreferrer">
                                    Source
                                </a>
                            </td>
                        </tr>
                        {/*<tr>
                            <td>Blender Animation Exporter</td>
                            <td>Miclee</td>
                            <td>Simple exporter script for Blender animations.</td>
                            <td>
                                <Badge bg="mythos">Community Tool</Badge>
                            </td>
                            <td>
                                <a href="https://github.com/LegendsModding/Blender-Anims-Exporter" target="_blank" rel="noreferrer">
                                    Source
                                </a>
                            </td>
                        </tr>*/}
                        <tr>
                            <td>ContentLogEnabler</td>
                            <td>LukeFZ</td>
                            <td>Frida script to print content log messages to console.</td>
                            <td>
                                <Badge bg="mythos">Community Tool</Badge>
                            </td>
                            <td>
                                <a href="https://github.com/LegendsModding/ContentLogEnabler" target="_blank" rel="noreferrer">
                                    Source
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td>Structure Editor</td>
                            <td>LukeFZ</td>
                            <td>Tool to edit structures in Minecraft Legends.</td>
                            <td>
                                <Badge bg="mythos">Community Tool</Badge>
                            </td>
                            <td>
                                <a href="https://badger.lukefz.xyz" target="_blank" rel="noreferrer">
                                    Website
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td>Blockbench</td>
                            <td>Jannis</td>
                            <td>A modeling tool used by most Minecraft modders for creating models, animations, and more.</td>
                            <td>
                                <Badge bg="warning">Modeling Tool</Badge>
                            </td>
                            <td>
                                <a href="https://www.blockbench.net/quickstart" target="_blank" rel="noreferrer">
                                    Website
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td>Blockbench Legends Exporter</td>
                            <td>Mojang</td>
                            <td>Blockbench plugin to export models to the Legends model format.</td>
                            <td>
                                <Badge bg="info">Blockbench Plugin</Badge>
                            </td>
                            <td>
                                <a href="https://github.com/Mojang/legends-blockbench-plugin/" target="_blank" rel="noreferrer">
                                    Source
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Col>
        </Row>
        <Row>
            <Col xs={12} md={6} className="mb-3">
                <Card>
                    <Card.Header>
                        <Card.Title>
                            Official Documentation
                        </Card.Title>
                        <span>

                        </span>
                    </Card.Header>
                    <Card.Body>
                        <Card.Text>
                            <p>
                                This documentation is the official release for Minecraft Legends. It has been written by the developers of the game
                                and is fully approved by Mojang. This documentation is the most up-to-date and accurate documentation available.
                            </p>
                        </Card.Text>
                    </Card.Body>
                    <Card.Footer>
                        <Row className="justify-content-between">
                            <Col>
                                <div className="d-block d-md-none">
                                    <Badge bg="mojang" className="me-2">Mojang</Badge>
                                    <Badge bg="dark">BBI</Badge>
                                </div>
                                <div className="d-none d-md-block">
                                    <Badge bg="mojang" className="me-2">Mojang Approved</Badge>
                                    <Badge bg="dark">BBI Approved</Badge>
                                </div>
                            </Col>
                            <Col className="text-end">
                                <a href="https://github.com/Mojang/minecraft-legends-docs/" target="_blank" rel="noreferrer" className="m-btn m-btn-primary w-100 px-3">
                                    <span className="d-none d-md-inline-block">Explore Official Docs</span>
                                    <span className="d-inline-block d-md-none">Explore</span>
                                </a>
                            </Col>
                        </Row>
                    </Card.Footer>
                </Card>
            </Col>
            <Col xs={12} md={6}>
                <Card>
                    <Card.Header>
                        <Card.Title>
                            Community Documentation
                        </Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Card.Text>
                            <p>
                                This documentation is unofficial and is not approved by Mojang. It is written by the community and is not guaranteed to be
                                up-to-date or accurate. This documentation is a work in progress and is not complete.
                            </p>
                        </Card.Text>
                    </Card.Body>
                    <Card.Footer>
                        <Row className="justify-content-between">
                            <Col>
                                <div className="d-block d-md-none">
                                    <Badge bg="mythos">Mythos</Badge>
                                </div>

                                <div className="d-none d-md-block">
                                    <Badge bg="mythos">Mythos Approved</Badge>
                                </div>
                            </Col>
                            <Col className="text-end">
                                <a href="https://docs.legendsmodding.com" target="_blank" rel="noreferrer" className="m-btn m-btn-primary w-100 px-3">
                                    <span className="d-none d-md-inline-block">Explore Community Docs</span>
                                    <span className="d-inline-block d-md-none">Explore</span>
                                </a>
                            </Col>
                        </Row>
                    </Card.Footer>
                </Card>
            </Col>
        </Row>
        <Row className="mb-5">
            <Col>
                <hr className="divider mx-auto mt-4" />
            </Col>
        </Row>
    </Container >
}

export default ResourcesPage;