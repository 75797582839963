import { Link, useNavigate } from "react-router-dom";
import { Nav, NavDropdown } from "react-bootstrap";
import { useMythosIdentity } from "../contexts/IdentityContext";

import LoginModal from "./modals/LoginModal";
import RegisterModal from "./modals/RegisterModal";
import { AccountRoles } from "../utilities/enums";

const Identity = () => {
    const navigate = useNavigate();
    const { authenticated, identity, resetIdentity } = useMythosIdentity();

    const handleLogout = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        resetIdentity();
        navigate('/');
    };

    if (!authenticated) {
        return <>
            <LoginModal /> | <RegisterModal />
        </>
    }

    let isAdmin = identity.roles?.find(role => role.id === AccountRoles.MythosAdministrator)

    return <Nav style={{ flexDirection: "row-reverse" }}>
        <NavDropdown title={identity.username} menuVariant="dark" className="fs-5">
            <NavDropdown.Item as={Link} to={`/profile/${identity.username}`}>Profile</NavDropdown.Item>
            <NavDropdown.Item as={Link} to={`/mymyths`}>My Myths</NavDropdown.Item>
            {isAdmin && (<NavDropdown.Item as={Link} to="/admin">Admin</NavDropdown.Item>)}
            <NavDropdown.Item as={Link} to="/settings">Settings</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
        </NavDropdown>
    </Nav>
}

export default Identity;