import { Col, Row } from "react-bootstrap";

const EndDivider = () => {
    return (
        <Row className="mb-5">
            <Col>
                <hr className="divider mx-auto mt-0 mt-md-4" />
            </Col>
        </Row>
    )
}

export default EndDivider;