import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, Form, Card, Badge } from "react-bootstrap";
import { Download } from "react-bootstrap-icons";
// import { ChatLeftFill, Download, Star, StarFill, StarHalf } from "react-bootstrap-icons";

import ApiService from "../services/ApiService";

import { getMythImage } from "../utilities/Helpers";
import { useMythosIdentity } from "../contexts/IdentityContext";
import EndDivider from "../components/common/EndDivider";

const MythsPage = () => {
    const { authenticated } = useMythosIdentity();
    const [loading, setLoading] = useState<boolean>(false);
    const [myths, setMyths] = useState<[]>([]);
    const [creatorId, setCreatorId] = useState<number>();
    const [categoryId, setCategoryId] = useState<number>();
    const [creators, setCreators] = useState<any[]>([]);
    const [categories, setCategories] = useState<any[]>([]);
    const [gameMode, setGameMode] = useState<"Campaign" | "PVP">();

    useEffect(() => {
        ApiService.get('/myths').then((response) => {
            setMyths(response.data);
        }).catch(console.log).finally(() => {
            setLoading(false);
        });

        ApiService.get('/myths/categories').then((response) => {
            setCategories(response.data);
        }).catch(console.log).finally(() => {
            setLoading(false);
        });

        ApiService.get('/myths/creators').then((response) => {
            setCreators(response.data);
        }).catch(console.log).finally(() => {
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        setLoading(true);
        let url = '/myths?';
        if (creatorId) {
            url += `creatorId=${creatorId}&`;
        }
        if (categoryId) {
            url += `categoryId=${categoryId}&`;
        }
        if (gameMode) {
            url += `gameMode=${gameMode}`;
        }

        ApiService.get(url).then((response) => {
            setMyths(response.data);
        }).catch(console.log).finally(() => {
            setLoading(false);
        });
    }, [categoryId, creatorId, gameMode]);

    function handleCreatorChange(e: any) {
        if (creatorId === e.currentTarget.value || e.currentTarget.value === 0) {
            setCreatorId(undefined);
            return;
        }
        setCreatorId(e.target.value);
    }

    function handleCategoryChange(e: any) {
        if (categoryId === e.target.value) {
            setCategoryId(undefined);
            return;
        }
        setCategoryId(e.target.value);
    }

    function handleGameModeChange(e: any) {
        if (gameMode === e.target.value) {
            setGameMode(undefined);
            return;
        }
        setGameMode(e.target.value);
    }

    const Myths = () => {
        if (!myths || loading) return <Col>Loading myths...</Col>;
        if (myths.length === 0) return <Col>No myths found</Col>
        let results = myths.map((myth: any) => (
            <Col key={myth.id} xs={12} md={6} lg={4} className="mb-4">
                <Link to={`/myth/${myth.id}`}>
                    <Card>
                        <Card.Body>
                            <Card.Img className="br-0" src={getMythImage(myth.default_image)} />
                            <Row className="justify-content-between">
                                <Col>
                                    <span className="mt-2 fs-3">{myth.name}</span>
                                    <br />
                                    by {myth.creator.username}
                                    <br />
                                    {
                                        myth.latest_version && (
                                            <i className="text-muted fs-8">
                                                Latest version v{myth.latest_version}
                                            </i>
                                        )
                                    }
                                    {
                                        !myth.latest_version && (
                                            <i className="text-muted fs-8">
                                                No versions yet
                                            </i>
                                        )
                                    }
                                </Col>
                                <Col className="mt-2" md={3}>
                                    <Badge bg="success">{myth.game_mode}</Badge>
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer className="d-flex flex-column justify-content-end">
                            <Row className="justify-content-between">
                                <Col>
                                    {myth.downloads}&nbsp;<Download />&nbsp;
                                    {/*0&nbsp;<ChatLeftFill />
                                </Col>
                                <Col className="text-end">
                                    <StarFill />
                                    <StarFill />
                                    <StarFill />
                                    <StarHalf />
                                    <Star />*/}
                                </Col>
                            </Row>
                        </Card.Footer>
                    </Card>
                </Link>
            </Col>
        ));

        return <Row>{results}</Row>;
    }

    return (
        <Container className="pt-4 pb-5 pt-md-3 pb-md-5">
            <h1 className="text-center header w-75 mx-auto mt-3 mb-4 pb-3">Myths</h1>
            <hr className="large-divider d-block d-md-none" />
            <Row className="w-100 w-md-75 mx-auto">
                <Col>
                    <h5>Creator</h5>
                    <Form.Select aria-label="Creator" onChange={handleCreatorChange}>
                        <option value="0">Select Creator</option>
                        {creators.map((creator: any) => (
                            <option key={creator.id} value={creator.id}>{creator.username} ({creator.myth_count})</option>
                        ))}
                    </Form.Select>
                </Col>
                <Col>
                    <h5>Categories</h5>
                    <Form.Select aria-label="Category" onChange={handleCategoryChange}>
                        <option value="0">Select Category</option>
                        {categories.map((category: any) => (
                            <option key={category.id} value={category.id}>{category.name}</option>
                        ))}
                    </Form.Select>
                </Col>
                <Col className="mt-2 mt-md-0">
                    <h5>Game Mode</h5>
                    <button className={`m-btn m-btn-primary px-2 py-1 mt-1 me-1 ${gameMode === "Campaign" ? 'active' : ''}`} value={"Campaign"} onClick={handleGameModeChange}>Campaign</button>
                    <button className={`m-btn m-btn-primary px-2 py-1 mt-1 ${gameMode === "PVP" ? 'active' : ''}`} value={"PVP"} onClick={handleGameModeChange}>PVP</button>
                </Col>
                {
                    authenticated && (
                        <Col className="text-end mt-4 pt-3 d-none d-md-block">
                            <Link to='/myth/create' className="m-btn m-btn-primary p-2">
                                Create Myth
                            </Link>
                        </Col>
                    )
                }
            </Row>
            <hr className="large-divider my-3" />
            <Row className="mt-3 justify-content-center">
                <Myths />
            </Row>
            <EndDivider />
        </Container>
    );
}

export default MythsPage;