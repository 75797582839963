import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import { DashCircle } from "react-bootstrap-icons";

import AdminApiService from "../../services/AdminApiService";
import EditUserModal from "./modals/EditUserModal";
import ResetPasswordModal from "./modals/ResetPasswordModal";

const AdminUsers = () => {
    const [users, setUsers] = useState<Account[]>([]);
    const [roles, setRoles] = useState<Role[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        AdminApiService.get('/users').then((response: any) => {
            if (!response.success)
                throw new Error(response.message);
            setUsers(response.data);
        }).catch(console.log).finally(() => setLoading(false));

        AdminApiService.get('/roles').then((response: any) => {
            if (!response.success)
                throw new Error(response.message);
            setRoles(response.data);
        }).catch(console.log).finally(() => setLoading(false));
    }, []);

    const Users = () => {
        if (loading) return <tr><td colSpan={5}>Loading users...</td></tr>;
        if (!users || users.length === 0) return <tr><td colSpan={5}>No users found</td></tr>
        let results = users.map(user => {
            return (
                <tr key={user.id} className="text-center">
                    <td>{user.id}</td>
                    <td>
                        <Link to={`/profile/${user.username}`}>
                            {user.username}
                        </Link>
                    </td>
                    <td>{user.email}</td>
                    <td>{new Date(user.created_at).toLocaleString()}</td>
                    <td>
                        <Row className="justify-content-center g-2">
                            <Col>
                                <EditUserModal account={user} roles={roles} />
                            </Col>
                            <Col>
                                <ResetPasswordModal account={user} />
                            </Col>
                            <Col>
                                <Button className="m-btn m-btn-danger w-100" size={"sm"}>
                                    <DashCircle />
                                </Button>
                            </Col>
                        </Row>
                    </td>
                </tr>
            )
        });

        return <>{results}</>
    }

    return (
        <Container>
            <Row>
                <Col>
                    <h1>Users</h1>
                    <hr className="large-divider" />
                    <div style={{ overflow: "auto", height: 400 }}>
                        <Table className="dark-bg py-3 px-0 bi-3">
                            <thead>
                                <tr className="text-center">
                                    <th>ID</th>
                                    <th>Username</th>
                                    <th>Email</th>
                                    <th>Registered</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <Users />
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default AdminUsers;