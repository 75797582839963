import { Container, Row, Col } from "react-bootstrap";

const AdminRoles = () => {
    return (
        <Container>
            <Row>
                <Col>
                    <h1>Roles</h1>
                    <hr className="large-divider" />
                    <div style={{ overflow: "auto", height: 400 }}>
                        <p>
                            Not implemented yet.
                        </p>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default AdminRoles;