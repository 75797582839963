import { useState } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import { Key } from "react-bootstrap-icons";
import AdminApiService from "../../../services/AdminApiService";

const ResetPasswordModal = ({ account }: { account: Account }) => {
    const [show, setShow] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [successMessage, setSuccessMessage] = useState<string | undefined>();

    async function handleResetPassword(e: any) {
        e.preventDefault();

        setSuccessMessage(undefined);
        setErrorMessage(undefined);

        AdminApiService.post('/account/reset-password', { id: account.id }, true)
            .then((response) => {
                if (!response.success) {
                    throw new Error(response.message);
                } else setSuccessMessage('Password reset successfully.');
            }).catch((error) => {
                setErrorMessage(error.message);
                console.log(`Failed to reset password ${error.message}`);
            });
    }

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    return (
        <>
            <Modal show={show} onHide={handleClose} className="dark-modal" aria-labelledby="Reset Password Form" centered>
                <Form onSubmit={handleResetPassword}>
                    <Modal.Header closeButton>
                        <Modal.Title>Reset Password</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            Are you sure you want to reset this user's password?
                            <br /><br />
                            User Id: <strong>{account.id}</strong>
                            <br />
                            Username: <strong>{account.username}</strong>
                            <br />
                            Email: <strong>{account.email}</strong>
                        </p>
                        {errorMessage && <p className="text-danger">{errorMessage}</p>}
                        {successMessage && <p className="text-success">{successMessage}</p>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="m-btn m-btn-secondary" onClick={handleClose}>Cancel</Button>
                        <Button className="m-btn m-btn-primary" type="submit">
                            Confirm Reset
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <Button className="m-btn m-btn-info w-100" size={"sm"} onClick={handleShow}>
                <Key />
            </Button>
        </>
    );
}

export default ResetPasswordModal;