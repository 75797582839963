import { Container } from "react-bootstrap";

const PrivacyPolicyPage = () => {
    return <Container>
        <h1>Mythos Privacy Policy</h1>
        <p><i>Last Updated: July 6, 2023</i></p>

        <p>This Privacy Policy is designed to help you understand how Umbriel Studios ("we," "us," or "our") collect, use, and protect the information you provide when you use our Mythos Minecraft Legends Mod Distribution Platform ("Mythos," "the Service," or "Platform").</p>

        <h2>1. What Information We Collect</h2>
        <p>To provide the best service possible, we collect the bare minimum of data:</p>
        <ul>
            <li><b>Account Information:</b> When you sign up for Mythos, we ask for basic information such as your username, password, and email address to create and manage your account.</li>
            <li><b>Usage Information:</b> We collect information about how you use the Service, including the mods you download, the frequency of your use, and other interaction data. This information is collected to improve the overall functionality of the Service.</li>
        </ul>

        <h2>2. How We Use The Information</h2>
        <p>The information we collect is used to:</p>
        <ul>
            <li>Provide, maintain and improve the Service.</li>
            <li>Send you technical notices, updates, and support messages.</li>
            <li>Protect the security and integrity of the Service.</li>
        </ul>
        <p>We do not use your information for marketing or advertising purposes without your explicit consent.</p>

        <h2>3. Sharing Of Your Information</h2>
        <p>We do not sell, trade, or rent your personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information with our business partners and trusted affiliates for the purposes outlined above.</p>

        <h2>4. Protection Of Your Information</h2>
        <p>We adopt appropriate data collection, storage, and processing practices and security measures to protect against unauthorized access, alteration, disclosure, or destruction of your personal information.</p>

        <h2>5. Changes To This Privacy Policy</h2>
        <p>We may update our Privacy Policy from time to time. When we do, we will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last Updated" date at the top of this Privacy Policy.</p>

        <h2>6. Contact Us</h2>
        <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at <a href="mailto:legal+privacy@umbrielstudios.com">legal@umbrielstudios.com</a>.</p>
    </Container>;
}

export default PrivacyPolicyPage;