import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import { useMythosIdentity } from "../contexts/IdentityContext";
import AdminDashboard from "../components/admin/AdminDashboard";
import AdminUsers from "../components/admin/AdminUsers";
import AdminLogs from "../components/admin/AdminLogs";
import AdminMyths from "../components/admin/AdminMyths";
import AdminRoles from "../components/admin/AdminRoles";
import { AccountRoles } from "../utilities/enums";
import AdminMythRequests from "../components/admin/AdminMythRequests";
import EndDivider from "../components/common/EndDivider";

const AdminPage = () => {
    const navigate = useNavigate();
    const { authenticating, authenticated, identity } = useMythosIdentity();

    useEffect(() => {
        if (!authenticated && authenticating) return;
        if (!authenticated && !authenticating) navigate('/');
        if (authenticated && !identity.roles?.find(role => role.id === AccountRoles.MythosAdministrator)) navigate('/');
    }, [authenticated, authenticating, identity, navigate]);

    return <Container className="pt-4 pb-5 py-md-5">
        <h1 className="text-center header w-75 mx-auto mt-3 mb-4 pb-3">Admin Panel</h1>
        <hr className="large-divider" />
        <Row className="pt-3 mb-5 mb-md-0">
            <Tab.Container defaultActiveKey="dashboard">
                <Col xs={12} md={2} className="text-center">
                    <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                            <Nav.Link eventKey="dashboard" className="m-btn m-btn-link">Dashboard</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="users" className="m-btn m-btn-link">Users</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="roles" className="m-btn m-btn-link">Roles</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="myths" className="m-btn m-btn-link">Myths</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="requests" className="m-btn m-btn-link">Requests</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="logs" className="m-btn m-btn-link">Logs</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
                <Col>
                    <hr className="d-block d-md-none mt-3" />
                    <Tab.Content className="fs-8 fs-md-5">
                        <Tab.Pane eventKey="dashboard">
                            <AdminDashboard />
                        </Tab.Pane>
                        <Tab.Pane eventKey="users">
                            <AdminUsers />
                        </Tab.Pane>
                        <Tab.Pane eventKey="roles">
                            <AdminRoles />
                        </Tab.Pane>
                        <Tab.Pane eventKey="myths">
                            <AdminMyths />
                        </Tab.Pane>
                        <Tab.Pane eventKey="requests">
                            <AdminMythRequests />
                        </Tab.Pane>
                        <Tab.Pane eventKey="logs">
                            <AdminLogs />
                        </Tab.Pane>
                    </Tab.Content>
                </Col>
            </Tab.Container>
        </Row>
        <EndDivider />
    </Container>
}

export default AdminPage;