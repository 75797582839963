class StorageService {
    set(key: string, value: any) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    async get(key: string) {
        const value = await localStorage.getItem(key);
        if (value === null) return null;
        return JSON.parse(value);
    }

    remove(key: string) {
        localStorage.removeItem(key);
    }
}

const storage = new StorageService();
export default storage;