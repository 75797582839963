import { BrowserRouter as Router } from "react-router-dom";
import { Container } from "react-bootstrap";
import "./styles/App.min.css";

import { Header, Footer } from "./components/Layout";
import AppRouter from "./components/AppRouter";
import MythosProvider from "./providers/MythosProvider";

const App = () => {
  return (
    <Router>
      <MythosProvider>
        <Header />
        <Container fluid className='g-0' data-bs-theme="dark">
          <div className="content">
            <AppRouter />
          </div>
          <div className="push"></div>
        </Container>
        <Footer />
      </MythosProvider>
    </Router>
  );
}

export default App;
