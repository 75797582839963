import { Container } from "react-bootstrap";

const TermsofServicePage = () => {
    return <Container>
        <h1>Mythos Terms of Service</h1>
        <p><i>Last Updated: July 6, 2023</i></p>

        <p>Welcome to Mythos, a Minecraft Legends Mod Distribution Platform provided by Umbriel Studios ("we," "us," or "our"). By using our Service, you agree to these Terms of Service. Please read them carefully.</p>

        <h2>1. User Conduct</h2>
        <p>You agree not to use the Service for any illegal or harmful purpose. If we become aware of any illegal material or activities on our Service, we will report it to the appropriate authorities and promptly remove such material. Abusing the service, including but not limited to uploading harmful content or causing undue strain on our servers, will lead to account revocation and an IP ban.</p>

        <h2>2. User Generated Content</h2>
        <p>Any myths, mods or other content created and uploaded by users to the Service ("User Content") is owned by the creator. By uploading User Content, you represent and warrant that you own the rights to the User Content or are otherwise authorized to upload it to the Service.</p>

        <p>We do not claim ownership over ANY User Content. However, by uploading User Content, and with your permission, you grant us a worldwide, non-exclusive, royalty-free right and license to use, reproduce, display, perform, adapt, modify, distribute, have distributed, and promote the User Content in connection with the Service.</p>

        <p>User Content does not reflect the views or opinions of Umbriel Studios and we are not responsible for any legal issues regarding redistribution of copyrighted code and assets. If you believe that your copyrighted work has been copied in a way that constitutes copyright infringement, please contact us immediately.</p>

        <h2>3. Limitation of Liability</h2>
        <p>To the maximum extent permitted by law, we are not liable for any direct, indirect, punitive, incidental, special, consequential, or exemplary damages, including without limitation damages for loss of profits, goodwill, use, data, or other intangible losses, that result from the use of, or inability to use, this service.</p>

        <h2>4. Changes to the Terms of Service</h2>
        <p>We may modify these Terms of Service from time to time. We will notify you of any material changes by posting the new Terms of Service on this page.</p>

        <h2>5. Contact Us</h2>
        <p>If you have any questions or concerns about our Terms of Service, please contact us at <a href="mailto:legal+terms@umbrielstudios.com">legal@umbrielstudios.com</a>.</p>
    </Container>;
}

export default TermsofServicePage;