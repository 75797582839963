import { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

const EditVerionModal = ({ currentVersion, versionId, callback }: { currentVersion: string, versionId: number, callback: (versionId: number, newVersion: string) => void }) => {
    const [version, setVersion] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string | undefined>();

    const [show, setShow] = useState<boolean>(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const handleUpdate = async () => {
        setErrorMessage(undefined);

        if (!version)
            return setErrorMessage('Version is required.');

        callback(versionId, version);
        handleClose();
    };

    const handleVersionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setVersion(event.target.value);
        setErrorMessage(undefined);
    };

    return <>
        <Modal show={show} onHide={handleClose} className="dark-modal" size="sm" aria-labelledby="Login Form" centered>
            <Form>
                <Modal.Header closeButton>
                    <Modal.Title id="edit-version">Edit Version</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <div className="text-start">
                        <Form.Group className="mb-3" controlId="Email">
                            <Form.Label>Version</Form.Label>
                            <Form.Control type="text" placeholder="Version" defaultValue={currentVersion} onChange={handleVersionChange} autoFocus required />
                            <Form.Control.Feedback type="invalid">
                                Version is required
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                    {errorMessage && (<p className="text-danger">{errorMessage}</p>)}
                    <hr />
                    <Row>
                        <Col>
                            <Button className="m-btn m-btn-secondary w-100" onClick={handleClose}>Cancel</Button>
                        </Col>
                        <Col>
                            <Button className="m-btn m-btn-primary w-100" type="submit" onClick={handleUpdate}>Update</Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Form>
        </Modal>
        <Button className="m-btn m-btn-primary me-2" size={"sm"} type="button" onClick={handleShow}>Edit</Button>
    </>
}

export default EditVerionModal