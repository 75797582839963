import { useState } from "react";
import { useMythosIdentity } from "../../contexts/IdentityContext";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

import icon from '../../styles/images/icons/icon.png';

const RegisterModal = () => {
    const { register, authenticating } = useMythosIdentity();

    const [email, setEmail] = useState<string>('');
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string | undefined>();

    const [show, setShow] = useState<boolean>(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const handleLogin = async () => {
        setErrorMessage(undefined);

        if (!email)
            return setErrorMessage('Email is required.');
        if (!username)
            return setErrorMessage('Username is required.');
        if (!password)
            return setErrorMessage('Password is required.');

        register(email, username, password).then((result) => {
            if (!result.success)
                setErrorMessage(result.message);
        });
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
        setErrorMessage(undefined);
    };

    const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUsername(event.target.value);
        setErrorMessage(undefined);
    };

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
        setErrorMessage(undefined);
    };

    return <>
        <Modal show={show} onHide={handleClose} className="dark-modal" aria-labelledby="Login Form" centered>
            <Modal.Body className="text-center">
                <img src={icon} alt="icon" className="w-25 mb-3" />
                <h3 className="text-light">Register an Account</h3>
                <hr className="large-divider" />
                <Form className="text-start">
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="Email">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control type="email" placeholder="Enter email" onChange={handleEmailChange} autoFocus />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="Username">
                                <Form.Label>Username</Form.Label>
                                <Form.Control type="text" placeholder="Enter Username" onChange={handleUsernameChange} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group className="mb-3" controlId="Password">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" placeholder="Password" onChange={handlePasswordChange} />
                    </Form.Group>
                </Form>
                {errorMessage && (<p className="text-danger">{errorMessage}</p>)}
                <hr className="large-divider" />
                <Row>
                    <Col>
                        <Button className="m-btn m-btn-secondary w-100" onClick={handleClose}>Cancel</Button>
                    </Col>
                    <Col>
                        <Button className="m-btn m-btn-primary w-100" onClick={handleLogin} disabled={authenticating}>{authenticating ? 'Waiting...' : 'Register'}</Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
        <button onClick={handleShow} className="btn-link text-light">Register</button>
    </>
}

export default RegisterModal