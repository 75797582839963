import { Col, Container, Row } from "react-bootstrap";

const AboutPage = () => {
    return <Container className="pt-4 pb-5 py-md-5">
        <h1 className="text-center header w-75 mx-auto mt-3 mb-4 pb-3">What is Mythos?</h1>
        <hr className="large-divider" />
        <Row>
            <Col>
                <p>
                    Mythos is a Mod Distribution service created by the Legends Modding team that allows creators to create, share, and play myths with community members.
                    The goal of the platform is to help grow the modding community for the game Minecraft Legends and share resources and Myths with everyone.
                </p>
            </Col>
        </Row>
        <hr className="large-divider" />
        <Row>
            <Col>
                <h1 className="header">What is a Myth?</h1>
                <p>
                    A myth is a custom game mode that can be played with friends or the community.<br />
                    Myths can be anything from a simple game mode to a full-fledged adventure map.
                </p>
            </Col>
        </Row>
        <Row>
            <Col>
                <h1 className="header">Copyright</h1>
                <p>
                    Mythos, Umbriel Studios, and Legends Modding are not affiliated with Mojang Studios or Microsoft.
                    Minecraft is a trademark of Mojang Studios. Mojang Studios is a trademark of Microsoft.
                    Myths on this website are created by the community, for the community and may not be used in any "Modpack"
                    without the explicit permission from the creator.
                </p>
            </Col>
        </Row>
        <Row className="mb-5">
            <Col>
                <hr className="divider mx-auto mt-4" />
            </Col>
        </Row>
    </Container>;
}

export default AboutPage;