import axios, { AxiosHeaders } from 'axios';
import { getIdentity } from './IdentityService';

const apiUrl = process.env.REACT_APP_API_HOST + '/api/admin';

class ApiService {
    corsHeaders = new AxiosHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': "*",
        'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
        'Access-Control-Allow-Headers': 'Content-Type',
    });

    async authHeaders(file?: boolean) {
        if (file) {
            delete this.corsHeaders['Content-Type'];
        }

        const identity = await getIdentity();
        if (identity.accessToken) {
            this.corsHeaders['Authorization'] = "Bearer " + identity.accessToken;
        }

        return this.corsHeaders;
    };

    public async get(requestUrl: string) {
        let headers = await this.authHeaders();
        let response = await axios.get(apiUrl + requestUrl, { headers });
        return response.data;
    }

    public async post(requestUrl: string, data?: any, file?: boolean) {
        let headers = await this.authHeaders(file);
        let response = await axios.post(apiUrl + requestUrl, (file) ? data : JSON.stringify(data), { headers });
        return response.data;
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ApiService();