import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col, Modal, Carousel, Card, Button, Badge } from "react-bootstrap";
import { FlagFill } from "react-bootstrap-icons";

import { useMythosIdentity } from "../contexts/IdentityContext";
import ApiService from "../services/ApiService";
import DownloadService from "../services/DownloadService";
import { formatDescription, getMythImage } from "../utilities/Helpers";

import discord from "../styles/images/icons/discord-white.png";
import github from "../styles/images/icons/github-white.png";
import twitter from "../styles/images/icons/twitter-white.png";
import EndDivider from "../components/common/EndDivider";

const MythPage = () => {
    const navigate = useNavigate();
    const { mythId } = useParams<{ mythId: string }>();
    const { identity } = useMythosIdentity();
    const [myth, setMyth] = useState<any>();
    const [show, setShowing] = useState<boolean>(false);
    const [image, setImage] = useState<string>("");

    useEffect(() => {
        ApiService.get(`/myth/${mythId}`).then((result) => {
            if (!result.success)
                navigate('/');
            setMyth(result.data);
        });
    }, [mythId, navigate]);

    const handleShow = () => setShowing(true);
    const handleClose = () => setShowing(false);

    const handlePreview = (e: any) => {
        e.preventDefault();
        setImage(e.currentTarget.firstChild.src);
        handleShow();
    }

    const downloadMythByHash = (e: any) => {
        e.preventDefault();
        let downloadUrl = DownloadService.getMythByHash(e.currentTarget.value);
        window.open(downloadUrl, '_blank');
    }

    return (
        <Container className="py-5">
            {
                myth && myth.creator.id === identity.id && (
                    <Row className="mb-3">
                        <Col className="text-end">
                            <Link to={`/myth/edit/${mythId}`} className="m-btn m-btn-primary p-2">
                                Edit Myth
                            </Link>
                        </Col>
                    </Row>
                )
            }
            <Row className="w-75 mx-auto">
                <Col xs={12} md={4}>
                    <div>
                        <h2 className="header">
                            {myth?.name}
                        </h2>
                        <i className="ms-2 fs-6">
                            <Link to={`/profile/${myth?.creator?.username}`}>
                                by {myth?.creator?.username}
                            </Link>
                        </i>
                        <hr />
                        <p>
                            {myth?.short_description}
                        </p>
                        <hr />
                        <p>
                            <i>
                                <b>Category:</b> {myth?.category?.name}
                            </i>
                        </p>
                    </div>
                </Col>
                <Col className="mb-3">
                    <Card className="p-2">
                        <Carousel interval={null}>
                            {
                                myth && myth.youtube_link && myth.youtube_link.length > 0 && (
                                    <Carousel.Item>
                                        <iframe
                                            className="w-100"
                                            src={`https://youtube.com/embed/${myth.youtube_link}`}
                                            title={myth.name}
                                            allowFullScreen
                                            style={{
                                                height: "470px",
                                                marginBottom: "-6px"
                                            }}
                                        />
                                    </Carousel.Item>
                                )
                            }
                            {
                                myth && myth.images && myth.images.length > 0 && myth.images.map((image: any) =>
                                    <Carousel.Item key={image.id} onClick={handlePreview}>
                                        <img
                                            className="d-block w-100"
                                            src={getMythImage(image.image_hash)}
                                            alt={myth.name}
                                        />
                                    </Carousel.Item>
                                )
                            }
                            {
                                myth && (!myth.images || myth.images.length === 0) && (
                                    <Carousel.Item>
                                        <img
                                            className="d-block w-100"
                                            src={getMythImage(myth.default_image)}
                                            alt={myth.name}
                                        />
                                    </Carousel.Item>
                                )
                            }
                        </Carousel>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className="mb-3">
                        <Card.Body>
                            <Row className="mt-1">
                                {myth &&
                                    (<>
                                        <Col xs={12} md={6} className="pt-2">
                                            {myth.discord_link && (
                                                <a href={`https://discord.gg/${myth.discord_link}`} className="m-btn m-btn-discord p-2" title="Discord">
                                                    <img src={discord} alt="Discord" className="mb-1" height={20} />
                                                </a>
                                            )}
                                            {myth.github_link && (
                                                <a href={`https://github.com/${myth.github_link}`} className="m-btn m-btn-dark p-2 ms-2" title="GitHub">
                                                    <img src={github} alt="Github" className="mb-1" height={20} />
                                                </a>
                                            )}
                                            {myth.twitter_link && (
                                                <a href={`https://twitter.com/${myth.twitter_link}`} className="m-btn m-btn-info p-2 ms-2" title="Twitter/X">
                                                    <img src={twitter} alt="Twitter/X" className="mb-1" height={20} />
                                                </a>
                                            )}
                                        </Col>
                                        <Col xs={12} md={6} className="text-end">
                                            <Button onClick={() => navigate(`/report/myth/${myth.id}`)} className="m-btn m-btn-danger">
                                                <FlagFill className="me-1" height={20} />
                                                Report
                                            </Button>
                                            {myth.versions && myth.versions.length > 0 && (
                                                <Button className="m-btn m-btn-primary ms-2" value={myth.versions[0].file_hash} onClick={downloadMythByHash}>
                                                    Download
                                                </Button>
                                            )}
                                            {/*<Button className="m-btn m-btn-secondary ms-2" disabled>
                                                Install
                                            </Button>*/}
                                        </Col>
                                    </>)
                                }
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <Card.Body>
                            <Row className="mt-1 fs-8 mb-1">
                                <Col>
                                    <b>Game Mode:</b>
                                    <br />
                                    {myth?.game_mode}
                                </Col>
                                <Col>
                                    <b>Release Date:</b>
                                    <br />
                                    {new Date(myth?.release_date).toLocaleDateString()}
                                </Col>
                                <Col>
                                    <b>Latest Version:</b>
                                    <br />
                                    {myth && myth.versions && myth.versions.length > 0 ? `v${myth.versions[0].version}` : "N/A"}
                                </Col>
                                <Col>
                                    <b>Downloads:</b>
                                    <br />
                                    {myth?.downloads}
                                </Col>
                                <Col>
                                    <b>Compatible:</b>
                                    <br />
                                    {myth?.compatible_versions ?? "N/A"}
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="g-3">
                <Col>
                    <div>
                        <h5>
                            About this Myth
                        </h5>
                        <hr className="large-divider" />
                        <p dangerouslySetInnerHTML={{
                            __html: `${formatDescription(myth?.long_description)}`
                        }} />
                    </div>
                    <Card className="dark-bg mt-3 d-none">
                        <Card.Header>
                            <span className="fs-5">
                                Comments and Reviews
                            </span>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <p className="text-muted">
                                        No comments or reviews yet.
                                    </p>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={4}>
                    <Card className="">
                        <Card.Header>
                            <span className="fs-5">
                                Version history
                            </span>
                        </Card.Header>
                        <Card.Body style={{ maxHeight: 200 }}>
                            {myth && (myth.versions && myth.versions.length > 0) ? myth.versions.map((version: any) => {
                                let index = myth.versions.indexOf(version);
                                return (<Row key={version.version}>
                                    <Col xs={8}>
                                        -&nbsp;v{version.version}&nbsp;
                                        {index === 0 && (<>
                                            <Badge bg="success">
                                                Latest
                                            </Badge>
                                            &nbsp;
                                        </>)}
                                        <Badge bg="primary">
                                            {new Date(version.upload_date).toLocaleDateString()}
                                        </Badge>
                                    </Col>
                                    <Col xs={4} className="text-end">
                                        <Button onClick={downloadMythByHash} className="m-btn m-btn-primary px-1 py-0" value={version.file_hash} size={"sm"}>
                                            Download
                                        </Button>
                                    </Col>
                                    {/*<Col xs={12}>
                                        <p className="text-muted">
                                            Changelog
                                        </p>
                                    </Col>*/}
                                    <Col xs={12}>
                                        <hr />
                                    </Col>
                                </Row>)
                            }) : (
                                <Row>
                                    <Col>
                                        <p className="text-muted">
                                            No versions found.
                                        </p>
                                    </Col>
                                </Row>
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <EndDivider />
            <Modal show={show} onHide={handleClose} className="dark-modal" size="xl">
                <Modal.Body>
                    <img src={image} alt={myth?.name} className="w-100" />
                </Modal.Body>
            </Modal>
        </Container>
    );
}

export default MythPage;