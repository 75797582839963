import { useState, useEffect } from "react";

import ApiService from "../../services/AdminApiService";
import { Badge, Card, Col, Container, Row } from "react-bootstrap";

const AdminDashboard = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [dashboardStats, setDashboardStats] = useState<any>();

    useEffect(() => {
        setLoading(true);
        ApiService.get('/dashboard').then((response: any) => {
            if (!response.success)
                throw new Error(response.message);
            setDashboardStats(response.data);
        }).catch((error: any) => {
            console.log(error.message);
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    const DashboardStats = () => {
        if (loading) return <h4>Loading Dashboard</h4>
        return (<>
            <Col xs={12} md={6} lg={3}>
                <Card bg="dark" className="text-light">
                    <Card.Body>
                        <Card.Title>Users</Card.Title>
                        <Card.Text>
                            <span className="fs-1">
                                {dashboardStats?.userCount ?? 0}
                            </span>
                            <br />
                            <Badge bg="success">+{dashboardStats?.newUsers ?? 0} in 7 days</Badge>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
            <Col xs={12} md={6} lg={3}>
                <Card bg="dark" className="text-light">
                    <Card.Body>
                        <Card.Title>Myths</Card.Title>
                        <Card.Text>
                            <span className="fs-1">
                                {dashboardStats?.mythCount ?? 0}
                            </span>
                            <br />
                            <Badge bg="success">+{dashboardStats?.newMyths ?? 0} in 7 days</Badge>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
            <Col xs={12} md={6} lg={3}>
                <Card bg="dark" className="text-light">
                    <Card.Body>
                        <Card.Title>Tickets</Card.Title>
                        <Card.Text>
                            <span className="fs-1">
                                {dashboardStats?.ticketCount ?? 0}
                            </span>
                            <br />
                            <Badge bg="success">+{dashboardStats?.newTickets ?? 0} in 7 days</Badge>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
        </>)
    }

    return (
        <Container>
            <Row>
                <Col>
                    <h1>Dashboard <small></small></h1>
                    <hr className="large-divider" />
                    <Row className="g-3">
                        <DashboardStats />
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

export default AdminDashboard;