import { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import ApiService from "../../services/ApiService";
import { encodeFileBase64, getProfilePicture } from "../../utilities/Helpers";

const EditProfileModal = ({ account, callback }: { account: any, callback: (response: any) => void }) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [username, setUsername] = useState<string>(account.username);
    const [preview, setPreview] = useState<string>(getProfilePicture(account.username));
    const [file, setFile] = useState<File>();
    const [errorMessage, setErrorMessage] = useState<string>();
    const [successMessage, setSuccessMessage] = useState<string>();

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        if (e.target.files && e.target.files.length > 0) {
            let image = e.target.files[0];
            setFile(image);
            encodeFileBase64(image).then(setPreview);
        }
    }

    const handleUpload = (e: React.FormEvent<HTMLElement>) => {
        e.preventDefault();
        inputRef.current?.click();
    };

    async function handleUpdateProfile(e: any) {
        e.preventDefault();

        setSuccessMessage(undefined);
        setErrorMessage(undefined);

        const formData = new FormData();
        formData.append('username', username);
        if (file) {
            formData.append('picture', file);
        }

        let response = await ApiService.post('/profile/update', formData, true);

        if (!response.success) {
            setErrorMessage(response.message);
            throw new Error(`Failed to update: ${response.message}`);
        }

        callback(response.data);
        setSuccessMessage('Profile updated successfully.');
    }

    useEffect(() => {
    }, [account]);

    return (<Container>
        <Row>
            <Col>
                <h2 className="header">Edit Profile</h2>
                <hr className="large-divider-alt" />
                <Form onSubmit={handleUpdateProfile}>
                    <Row className="mb-3 w-100 w-md-75">
                        <Col xs={12} md={6} className="mb-3">
                            <Form.Group controlId="Username" className="mb-3">
                                <Form.Label>Username</Form.Label>
                                <Form.Control type="text" placeholder="Username" defaultValue={account.username} onChange={(e) => setUsername(e.target.value)} autoFocus />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                            <Row>
                                <span>Profile Picture</span>
                                <Col className="">
                                    <img src={preview} alt="pfp" height={128} width={128} />
                                </Col>
                                <Col>
                                    <Form.Group className="mt-3 me-4 mb-3" controlId="editProfileImage">
                                        <button onClick={handleUpload} className="dg-btn dg-btn-primary py-2 px-3 text-light">Upload</button>
                                        <input title="pfp" ref={inputRef} className="d-none" type="file" accept="image/png,image/jpg,image/jpeg,image/gif" onChange={handleFileChange} />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {errorMessage && <p className="text-danger">{errorMessage}</p>}
                    {successMessage && <p className="text-success">{successMessage}</p>}
                    <hr className="large-divider-alt" />
                    <Row>
                        <Col>
                            <Button className="m-btn m-btn-primary" onClick={handleUpdateProfile}>Save Changes</Button>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>
    </Container >)
}

export default EditProfileModal;