import { useEffect, useState } from "react";
import { Badge, Col, Container, Modal, Row, Table } from "react-bootstrap";

import AdminApiService from "../../services/AdminApiService";
import ReviewMythModal from "./modals/ReviewMythModal";

const AdminMythRequests = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [requests, setRequests] = useState<MythRequest[]>();

    useEffect(() => {
        setLoading(true);
        AdminApiService.get('/myths/requests').then((response) => {
            if (response && response.success) {
                setRequests(response.data);
            } else throw new Error(response.message);
        }).catch(console.log).finally(() => {
            setLoading(false);
        });
    }, []);

    const MythRequests = () => {
        const [showStatusModal, setShowStatusModal] = useState<boolean>(false);

        if (loading) return <tr><td colSpan={9}>Loading requests...</td></tr>;
        if (!requests || requests.length === 0) return <tr><td colSpan={9}>No requests found</td></tr>;

        return <>
            {requests.map(request => {
                const RequestStatus = () => {
                    switch (request.status) {
                        case 'Approved':
                            return <Badge bg="success">Approved</Badge>;
                        case 'Rejected':
                            return <Badge bg="danger" onClick={() => setShowStatusModal(true)} className="pointer">Rejected</Badge>
                        case 'Deleted':
                            return <Badge bg="danger" onClick={() => setShowStatusModal(true)} className="pointer">Deleted</Badge>
                        case 'Pending':
                        default:
                            return <Badge bg="warning">Pending</Badge>;
                    }
                }

                const StatusModal = () => {
                    return (
                        <Modal show={showStatusModal} onHide={() => setShowStatusModal(false)} className="dark-modal" aria-labelledby="Review Myth Form" centered>
                            <Modal.Header closeButton>
                                <Modal.Title>Request Status</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Row>
                                    <Col>
                                        Status: {request.status}<br />
                                        Reason: {request.reason ?? 'N/A'}<br />
                                    </Col>
                                </Row>
                            </Modal.Body>
                        </Modal>
                    )
                }

                return (
                    <tr key={request.id} className="text-center">
                        <td>{request.id}</td>
                        <td>{request.myth.id}</td>
                        <td>{request.myth.name}</td>
                        <td>{request.requestor.username}</td>
                        <td>{request.reviewer?.username ?? 'N/A'}</td>
                        <td>{new Date(request.submitted_at).toLocaleDateString()}</td>
                        <td>
                            <RequestStatus />
                            <StatusModal />
                        </td>
                        <td>
                            <ReviewMythModal requestId={request.id} mythId={request.myth.id} />
                        </td>
                    </tr >
                )
            })}
        </>;
    }

    return (
        <Container>
            <Row>
                <Col>
                    <h1>Myth Reviews</h1>
                    <hr className="large-divider" />
                    <div style={{ overflow: "auto", height: 400 }}>
                        <Table className="dark-bg py-3 px-0 bi-3 fs-6">
                            <thead>
                                <tr className="text-center">
                                    <th>ID</th>
                                    <th>Myth ID</th>
                                    <th>Myth Name</th>
                                    <th>Requestor</th>
                                    <th>Reviewer</th>
                                    <th>Submission Date</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <MythRequests />
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default AdminMythRequests;